import { useState, useEffect, useCallback } from 'react';
import { Card, Button, Dropdown } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { FormProvider, useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';
import cx from 'classnames';
import { FormMode } from 'core/formMode';
import { each, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import prepareFormData from 'utils/prepareFormData';

import { useAuthContext } from 'contexts/AuthContext';
import { useDialogManager } from 'contexts/DialogManagerContext';
import { useEntityContext } from 'contexts/EntityContext';

import usePortal from '../../../hooks/usePortal';
import ConfirmationExitModal from '../../ConfirmationExitModal/ConfirmationExitModal';
import FormLoader from '../../Loaders/FormLoader';
import Overlay from '../../Overlay/Overlay';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import Fieldsets from '../Fieldsets/Fieldsets';
import WorkflowReasonModal from '../WorkflowReason/WorkflowReasonModal';

import './EntityForm.module.scss';

const EntityMainActions = ({
  entityKey,
  entityId,
  mode,
  schema,
  menuActions,
  loading,
}) => {
  const availablePrintableDocs = schema?.printableDocuments?.filter(
    p => !p.use_in_grid,
  );

  return (
    <>
      <Button
        type="button"
        className="m-1"
        data-cy="save"
        disabled={mode === FormMode.readonly || !!loading}
        onClick={menuActions.save}
      >
        {loading ? (
          <i className="fas fa-spinner fa-spin mr-2" />
        ) : (
          <i className="far fa-save mr-2" />
        )}
        Save
      </Button>

      {mode !== FormMode.create && (
        <>
          {schema?.workflow?.availableSteps?.length > 0 && (
            <Dropdown>
              <Dropdown.Toggle
                data-cy="actions-dropdown"
                variant="primary-light"
                id="dropdown-basic"
              >
                Actions
                <i className="fas fa-sm fa-chevron-down ml-2" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {schema.workflow.availableSteps.map(action => (
                  <Dropdown.Item
                    data-cy={`action-${action.key}`}
                    key={action.key}
                    disabled={loading}
                    onClick={() => menuActions.move(action)}
                  >
                    <i
                      className={cx('fas mr-2', {
                        'fa-user-check': action.to.takeOwnership,
                        'fa-chevron-double-left':
                          action.to.reasonRequired && action.to.step,
                        'fa-chevron-double-right':
                          !action.to.reasonRequired && action.to.step,
                        'fa-thumbs-up text-success': action.to.outcome === 1,
                        'fa-thumbs-down text-danger': action.to.outcome === 2,
                      })}
                    />
                    <span>{action.name}</span>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}

          {availablePrintableDocs?.length > 0 && (
            <Dropdown>
              <Dropdown.Toggle
                id="btn-print"
                data-cy="print"
                variant="light-grey"
                className="m-1"
                disabled={loading}
              >
                Print
                <i className="fas fa-sm fa-chevron-down ml-2" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {availablePrintableDocs.map(doc => (
                  <Dropdown.Item
                    disabled={loading}
                    key={doc.id}
                    href={`/api/${entityKey}/${entityId}/doc/${doc.id}`}
                    target="_blank"
                  >
                    {doc.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </>
      )}
    </>
  );
};

EntityMainActions.propTypes = {
  entityKey: PropTypes.string.isRequired,
  entityId: PropTypes.number,
  mode: PropTypes.string.isRequired,
  schema: PropTypes.any,
  loading: PropTypes.any,
  menuActions: PropTypes.shape({
    move: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    takeOwnership: PropTypes.func.isRequired,
  }).isRequired,
};

EntityMainActions.defaultProps = {
  schema: undefined,
  entityId: undefined,
  loading: undefined,
};

const WrapperComponent = ({ children }: React.PropsWithChildren<any>) => (
  <Card className="m-4 border-0 shadow-sm">
    <Card.Body>{children}</Card.Body>
  </Card>
);

WrapperComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

type EntityFormProps = {
  entityState?: any;
  formMode?: any;
  entityActionBarRef?: any;
  entityId?: number;
  entityKey: string;
  modalLevel: number;
};

const EntityForm = ({
  entityState,
  formMode,
  entityId,
  entityKey,
  entityActionBarRef,
  modalLevel,
}: EntityFormProps) => {
  const target = usePortal(entityActionBarRef);
  const { getDialog, closeDialog, setDialogParam } = useDialogManager();

  const {
    state: { authenticatedUser },
  } = useAuthContext();

  const actions = useEntityContext();

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [showRejectReasonModal, setShowRejectReasonModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showConfirmExitModal, setShowConfirmExitModal] = useState(false);
  const [stepTo, setStepTo] = useState();

  const formMethods = useForm({
    resolver: joiResolver(entityState?.schema?.formValidation),
  });

  const {
    formState: { dirtyFields },
  } = formMethods;

  const dirtyKeysLength = Object.keys(dirtyFields).length;

  const closeForm = useCallback(() => {
    const isDirty = dirtyKeysLength > 0;
    if (isDirty) {
      setShowConfirmExitModal(true);
      return false;
    }

    closeDialog();
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirtyKeysLength]);

  useEffect(() => {
    setDialogParam({
      beforeHideCallback: closeForm,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeForm]);

  if (entityState?.isLoading) {
    return (
      <WrapperComponent>
        <FormLoader />
      </WrapperComponent>
    );
  }

  const entityFormSubmit = async (
    data: any,
    overrideAction: string | undefined = undefined,
    step: string | undefined = undefined,
  ) => {
    const transformedData = prepareFormData(entityState?.schema?.fields, data);
    const action = overrideAction ? actions[overrideAction] : actions[formMode];

    setLoadingSubmit(true);
    const response = await action(entityKey, transformedData, entityId, step);
    setLoadingSubmit(false);

    if (response === undefined) {
      // CLEMPE - error discoverability (developer is not returning fetch response)
      throw new Error('System could not determine fetch response');
    }

    // TODO: this will solve 90% of the cases, just just when server doesn't return a payload with error
    if (response?.error) {
      return;
    }

    const dialog = getDialog();
    if (dialog) {
      closeDialog(dialog.id);
      if (dialog.params?.afterSaveCallback) {
        dialog.params.afterSaveCallback({
          ...{ id: entityId }, // when updating, add entity id.
          ...response,
        });
      }
    }
  };

  const validateForm = async (formSchema, data) => {
    const resolver = await joiResolver(formSchema);

    const { values, errors } = await resolver(data, null, {
      shouldUseNativeValidation: false,
      fields: {},
    });

    if (!isEmpty(errors)) {
      each(Object.keys(errors), key => {
        formMethods.setError(key, errors[key]);
      });

      return undefined;
    }

    return values;
  };

  const save = async () => {
    if (formMode === FormMode.edit) {
      if (entityState?.schema.hasWorkflow) {
        const values = await validateForm(
          entityState?.schema.draftValidation,
          formMethods.getValues(),
        );

        if (!values) {
          return;
        }

        // draft save
        entityFormSubmit(values);
        return;
      }

      formMethods.handleSubmit(data => entityFormSubmit(data))();
      return;
    }

    formMethods.handleSubmit(data => entityFormSubmit(data))();
  };

  const takeOwnership = async () => {
    const values = await validateForm(
      entityState?.schema.draftValidation,
      formMethods.getValues(),
    );

    if (!values) {
      return;
    }

    entityFormSubmit(
      {
        ...values,
        workflow_owner: authenticatedUser.user_id,
      },
      'takeOwnership',
    );
  };

  const deleteEntity = () => {
    setShowConfirmDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (!entityId) return;
    const response = await actions.deleteEntity(entityKey, entityId);
    if (response?.error) return;

    setShowConfirmDeleteModal(false);

    const dialog = getDialog();
    if (dialog) {
      closeDialog(dialog.id);
      dialog.params.afterDeleteCallback({
        ...response,
        ...{ id: entityId },
      });
    }
  };

  const move = async ({ key, to }) => {
    if (to.takeOwnership) {
      takeOwnership();
      return;
    }

    const values = await validateForm(
      to.fullValidationRequired || to.outcome === 1
        ? entityState?.schema.formValidation
        : entityState?.schema.draftValidation,
      formMethods.getValues(),
    );

    if (!values) {
      return;
    }

    if (to.outcome === 1) {
      setStepTo(key);
      setShowApproveModal(true);
      return;
    }

    if (to.outcome === 2) {
      setStepTo(key);
      setShowRejectReasonModal(true);
      return;
    }

    if (to.reasonRequired) {
      setStepTo(key);
      setShowReasonModal(true);
      return;
    }

    formMethods.handleSubmit(data => entityFormSubmit(data, 'moveStep', key))();
  };

  const menuActions = {
    save,
    delete: deleteEntity,
    takeOwnership,
    move,
  };

  const portalElement = createPortal(
    <>
      <div className="d-flex align-items-center">
        <EntityMainActions
          entityKey={entityKey}
          entityId={entityId}
          mode={
            entityState?.formData?._is_deleted ? FormMode.readonly : formMode
          }
          schema={entityState?.schema}
          menuActions={menuActions}
          loading={loadingSubmit}
        />

        {entityState?.formData?._is_deleted && (
          <span className="ml-auto text-danger border border-danger py-1 px-2">
            Deleted
          </span>
        )}
        {formMode === FormMode.edit && !entityState?.formData?._is_deleted && (
          <Dropdown
            className="ml-auto h-100 mb-auto mt-auto nav-item nav-more-options"
            data-cy="nav-more-options"
            // variant="dark" todo: tscheck
          >
            <Dropdown.Toggle variant="white">
              <i className="fas fa-ellipsis-h" />
            </Dropdown.Toggle>

            <Dropdown.Menu alignRight>
              <Dropdown.Item
                data-cy="delete"
                active={false}
                href="#/action-1"
                onClick={menuActions.delete}
              >
                Delete Record
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    </>,
    target,
  );

  const modals = (
    <>
      {showReasonModal && (
        <WorkflowReasonModal
          show={showReasonModal}
          handleClose={() => {
            setShowReasonModal(false);
          }}
          title="More information required"
          body="Provide a reason why you want to perform this action."
          confirmActionText="Submit"
          confirmAction={reasonData => {
            const data = formMethods.getValues();

            setShowReasonModal(false);
            entityFormSubmit({ ...data, ...reasonData }, 'moveStep', stepTo);
          }}
        />
      )}
      {showRejectReasonModal && (
        <WorkflowReasonModal
          show={showRejectReasonModal}
          handleClose={() => {
            setShowRejectReasonModal(false);
          }}
          title="Are you sure you want to reject this record?"
          body="When rejecting this record, it will go into a read only state, and cannot be edited."
          confirmActionText="Reject"
          confirmAction={reasonData => {
            const data = formMethods.getValues();

            setShowRejectReasonModal(false);
            entityFormSubmit({ ...data, ...reasonData }, 'moveStep', stepTo);
          }}
        />
      )}

      {showApproveModal && (
        <ConfirmationModal
          title="By proceeding this record will be approved."
          body="By proceeding, this record will be approved and will reach the end of its workflow."
          show={showApproveModal}
          handleClose={() => setShowApproveModal(false)}
          confirmAction={() => {
            const data = formMethods.getValues();

            setShowApproveModal(false);
            entityFormSubmit(data, 'moveStep', stepTo);
          }}
          confirmType="primary"
        />
      )}

      {showConfirmExitModal && (
        <ConfirmationExitModal
          centered
          modalLevel={50}
          show={showConfirmExitModal}
          onHide={() => {
            setShowConfirmExitModal(false);
          }}
          onCancel={() => setShowConfirmExitModal(false)}
          onConfirm={() => {
            closeDialog();
          }}
          size="lg"
        />
      )}

      {showConfirmDeleteModal && (
        <ConfirmationModal
          title="Delete confirmation"
          body="Are you sure you want to delete this record?"
          show={showConfirmDeleteModal}
          handleClose={() => setShowConfirmDeleteModal(false)}
          confirmAction={confirmDelete}
          confirmType="danger"
        />
      )}
    </>
  );

  return (
    <div className="position-relative">
      {loadingSubmit && <Overlay />}
      <FormProvider {...formMethods}>
        {entityState?.schema && (
          <Fieldsets
            fields={entityState.schema.fields}
            fieldsets={entityState.schema.formLayout.fieldsets}
            data={entityState.formData}
            entityKey={entityKey}
            entityId={entityId}
            mode={
              entityState.formData?._is_deleted ? FormMode.readonly : formMode
            }
            modalLevel={modalLevel}
          />
        )}
      </FormProvider>
      {portalElement}
      {modals}
    </div>
  );
};

export default EntityForm;
