import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import EntityLogList from 'components/EntityLogList/EntityLogList';
import { IronBody } from 'components/Layout';
import { useEntityContext } from 'contexts/EntityContext';

import EntityHistoryList from '../../EntityHistoryList/EntityHistoryList';
import HistoryLoader from '../../Loaders/HistoryLoader';

type Props = {
  entityKey: string;
  entityId: number;
};

const EntityHistory = ({ entityKey, entityId }: Props) => {
  const [loading, setLoading] = useState(false);
  // TODO old version using history will be removed in V4
  const [showOldVersion, setShowOldVersion] = useState(false);

  const {
    getEntityState,
    fetchEntityHistories,
    fetchEntityLogs,
  } = useEntityContext();

  const entityState = getEntityState(entityKey, entityId);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await fetchEntityHistories(entityKey, entityId);
        await fetchEntityLogs(entityKey, entityId);
      } catch (error) {
        toast.error(error);
      }
      setLoading(false);
    };
    fetchData();
  }, [fetchEntityHistories, fetchEntityLogs, entityKey, entityId]);

  return (
    <IronBody>
      <button
        type="button"
        className="btn btn-link btn-sm mb-4"
        style={{ position: 'absolute', top: '-35px', right: '25px' }}
        onClick={() => setShowOldVersion(!showOldVersion)}
      >
        {showOldVersion
          ? 'Switch to the new history log'
          : 'Switch to the old history log'}
      </button>
      <div className="table-responsive">
        {!loading && entityState ? (
          showOldVersion ? (
            <EntityHistoryList
              histories={entityState.histories}
              schema={entityState.schema}
            />
          ) : (
            <EntityLogList
              entityKey={entityKey}
              entityId={entityId}
              logs={entityState.logs}
              schema={entityState.schema}
            />
          )
        ) : (
          <HistoryLoader />
        )}
      </div>
    </IronBody>
  );
};

export default EntityHistory;
