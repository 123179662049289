import { StorageObject } from '@april9/stack9-sdk';
import moment from 'moment';
import { getInitialLetter } from 'utils/text';

import EntityHistoryCircle from '../EntityHistoryCircle/EntityHistoryCircle';

import './EntityLogListItem.scss';

type Props = {
  log: StorageObject;
};

const EntityLogListItem = ({ log }: Props) => (
  <div className="EntityLogListItem">
    <div className="log-action mr-2">
      <EntityHistoryCircle action={log.metadata.action} />
      <div className="circle circle-gray rounded-circle">
        <strong>{getInitialLetter(log.metadata.useremail)}</strong>
      </div>
    </div>
    <div className="flex-grow-1">
      <p className="m-0">
        <span className="my-0 mr-3">
          <strong className="mr-1">
            {log.metadata.useremail || 'Unknown'}
          </strong>
          <span>{log.metadata.action}</span>
        </span>
        <span className="text-gray log-detail-date">
          {moment(log.createdOn).fromNow()},{' '}
          {moment(log.createdOn).format('LLL')}
        </span>
      </p>
    </div>
  </div>
);

export default EntityLogListItem;
