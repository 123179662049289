import { useFormContext } from 'react-hook-form';

import { FieldItem } from '@april9/stack9-sdk';
import cx from 'classnames';

import FieldLabel from './FieldLabel';

export type Props = {
  name: string;
  defaultValue?: any;
  fieldItem: FieldItem;
};

const TextField = ({ name, fieldItem, defaultValue }: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const { placeholder, ui_component_options, description } = fieldItem;

  const multiLine = ui_component_options?.multiLine || false;
  const readOnly = ui_component_options?.readOnly || false;
  const isText = ui_component_options?.text || false;

  const inputProps = {
    className: cx('form-control', { 'is-invalid': errors[name] }),
    placeholder,
    id: name,
    readOnly,
  };

  const maxLength = !isText ? { maxLength: 255 } : {};

  return (
    <FieldLabel fieldItem={fieldItem}>
      {!multiLine ? (
        <input
          type="text"
          {...inputProps}
          {...register(name)}
          defaultValue={defaultValue}
        />
      ) : (
        <textarea
          rows={4}
          {...inputProps}
          {...maxLength}
          {...register(name)}
          defaultValue={defaultValue}
        />
      )}
      {description && (
        <small className="form-text text-muted">{description}</small>
      )}
    </FieldLabel>
  );
};

export default TextField;
