import { useState, useEffect, useMemo } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import ReactSelect from 'react-select';

import {
  LabelValue,
  useMapSourceLabelValue,
  Utils,
} from '@april9/stack9-react';
import { FieldItem } from '@april9/stack9-sdk';
import { post } from 'core/fetch';
import { useDebounce } from 'use-debounce';

import { useDialogManager } from 'contexts/DialogManagerContext';

import FieldLabel from './FieldLabel';

type Props = {
  name: string;
  fieldItem: FieldItem;
  defaultValue?: Array<any>;
  options?: Array<any>;
  limit?: number;
  data?: any; // TODO: do we really need this? if so, we need that in the multi dropdown as well right? Could we get from the react-hooks context?
};

const MultiDropDown = ({
  name,
  options: propOptions,
  defaultValue,
  fieldItem,
  limit,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<Array<LabelValue>>([]);
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { openDialog } = useDialogManager();

  const {
    ui_component_options,
    placeholder,
    relationshipOptions,
    description,
  } = fieldItem;

  const {
    labelPropName,
    valuePropName,
  } = Utils.form.getComponentOptionsLabelValue(ui_component_options);

  const { mapLabelValue } = useMapSourceLabelValue(
    valuePropName,
    labelPropName,
  );

  const filterWhere = relationshipOptions?.filter_where;
  const fetchRelatedOptions = async (
    searchText?: string,
    fieldToQuery?: string,
  ) => {
    if (!relationshipOptions) return;
    setLoading(true);

    try {
      const items = await post(
        `/${relationshipOptions.related_entity}/search?limit=${limit}`,
        {
          $where: {
            ...(filterWhere ?? {}),
            ...(searchText && fieldToQuery
              ? { [fieldToQuery]: { $like: `%${searchText}%` } }
              : {}),
            _is_deleted: false,
          },
        },
      );

      setOptions(items.map(mapLabelValue));
      setLoading(false);
    } catch (err) {
      throw new Error(err);
    }
  };

  useEffect(() => {
    if (propOptions) {
      setOptions(propOptions.map(mapLabelValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transformedDefaultValue = useMemo(
    () =>
      defaultValue && defaultValue.length
        ? defaultValue.map(mapLabelValue)
        : undefined,
    [defaultValue, mapLabelValue],
  );

  const [inputText, setInputText] = useState();
  const [searchText] = useDebounce(inputText, 500);

  useEffect(() => {
    if (searchText || searchText === '') {
      fetchRelatedOptions(searchText, labelPropName);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <FieldLabel
      fieldItem={fieldItem}
      actions={
        <button
          type="button"
          className="btn btn-link btn-sm"
          disabled={ui_component_options?.readOnly}
          onClick={() => {
            openDialog({
              entityKey: relationshipOptions?.related_entity,
            });
          }}
        >
          Create new
        </button>
      }
    >
      <Controller
        control={control}
        name={name}
        defaultValue={transformedDefaultValue}
        render={({ field }) => (
          <ReactSelect
            {...field}
            styles={{
              control: provided => ({
                ...provided,
                borderColor: errors[name] ? 'red' : '#ced4da',
                minHeight: '3rem',
              }),
              menuPortal: base => ({ ...base, zIndex: 9999 }),
            }}
            onInputChange={setInputText}
            onMenuOpen={() => {
              if (!inputText) {
                fetchRelatedOptions();
              }
            }}
            menuPortalTarget={document.body}
            placeholder={placeholder}
            noOptionsMessage={() => (loading ? 'Loading...' : 'No Options')}
            options={options}
            id={field.name}
            closeMenuOnSelect={false}
            isMulti
            isDisabled={ui_component_options?.readOnly}
          />
        )}
      />

      {description && (
        <small className="form-text text-muted">{description}</small>
      )}
    </FieldLabel>
  );
};

MultiDropDown.defaultProps = {
  options: [],
  defaultValue: [],
  limit: 30,
};

export default MultiDropDown;
