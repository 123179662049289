import { createContext, useCallback, useContext, useReducer } from 'react';
import { toast } from 'react-toastify';

import {
  IEntitySchemaDefinition,
  IJsonEntityView,
  StorageObject,
} from '@april9/stack9-sdk';
import { get, post, put, remove, download } from 'core/fetch';
import { isEmpty } from 'lodash';
import { SearchRequest } from 'models/SearchRequest';
import moment from 'moment';
import PropTypes from 'prop-types';
import { serializeQuery } from 'utils/objectTransformer';
import { buildSchemaValidation } from 'utils/validations';

import downloadFile from '../utils/fileUtils';
import reducer, {
  initialState,
  ActionTypes,
  getStateName,
  IDetailEntityState,
} from './state/EntityState';

const getDefaultView = (views: IJsonEntityView[]) => {
  const viewWithIsDefault = views.find(({ isDefault }) => !!isDefault);

  return viewWithIsDefault ? viewWithIsDefault : views[0];
};

const getView = (views: IJsonEntityView[], searchRequest) => {
  const view = views.find(view => view.key === searchRequest.view);

  return view || getDefaultView(views);
};

interface EntityContextActions {
  resetEntityStates: (entityKey: string, entityId?: number) => Promise<void>;
  resetEntity: (entityKey: string) => void;
  resetEntityFormData: (entityKey: string) => void;
  getEntityState: (
    entityKey: string,
    entityId?: number,
  ) => IDetailEntityState | undefined;
  fetchOneByType: (entityKey: string, entityId?: number) => Promise<void>;
  fetchCountRelatedEntities: (
    entityKey: string,
    entityId: number,
  ) => Promise<void>;
  loadEntitySchema: (entityKey: string, entityId?: number) => Promise<any>;
  fetchEntityHookContent: (hookId: string) => Promise<void>;
  fetchAllByEntityName: (
    entityKey: string,
    query: SearchRequest,
  ) => Promise<void>;
  insertEntityAttachment: (
    entityKey: string,
    data: any,
    entityId: number,
  ) => Promise<any>;
  deleteEntityAttachment: (
    entityKey: string,
    entityId: number,
    attachmentId: number,
  ) => Promise<boolean>;
  deleteEntity: (entityKey: string, entityId: number) => Promise<any>;
  fetchEntityAttachments: (entityKey: string, entityId: number) => Promise<any>;
  fetchEntityHistories: (entityKey: string, entityId: number) => Promise<any>;
  fetchEntityLogs: (entityKey: string, entityId: number) => Promise<any>;
  fetchEntityComments: (entityKey: string, entityId: string) => Promise<any>;
  insertEntityComment: (
    entityKey: string,
    data: any,
    entityId: number,
  ) => Promise<any>;
  fetchEntityTasks: (entityKey: string, entityId: string) => Promise<any>;
  insertEntityTask: (
    entityKey: string,
    entityId: number,
    data: any,
  ) => Promise<any>;
  updateEntityTask: (
    entityKey: string,
    entityId: number,
    taskId: number,
    data: any,
  ) => Promise<any>;
  deleteEntityTask: (
    entityKey: string,
    entityId: number,
    taskId: number,
  ) => Promise<any>;
  insertFileUpload: (
    entityKey: string,
    name: string,
    data: any,
    onUploadProgress: (progressEvent: any) => void,
  ) => Promise<any>;
  create: (entityKey: string, data: any) => Promise<any>;
  edit: (entityKey: string, data: any, entityId: number) => Promise<any>;
  exportToExcel: (
    entityKey: string,
    searchRequest: SearchRequest,
  ) => Promise<any>;
  openDocument: (
    entityKey: string,
    documentId: number,
    searchRequest: SearchRequest,
  ) => void;

  returnStep: (entityKey: string, data: any, entityId: number) => Promise<any>;
  reject: (entityKey: string, data: any, entityId: number) => Promise<any>;
  takeOwnership: (
    entityKey: string,
    data: any,
    entityId: number,
  ) => Promise<any>;
  approve: (entityKey: string, data: any, entityId: number) => Promise<any>;
  moveStep: (
    entityKey: string,
    data: any,
    entityId: number,
    actionKey: string,
  ) => Promise<any>;
}

interface EntityContextState extends EntityContextActions {
  state: any;
}

export const EntityContext = createContext({} as EntityContextState);

const useEntityContext = () => useContext<EntityContextState>(EntityContext);

const EntityContextProvider = ({ children }: React.PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  /*
   * ACTIONS
   */
  const resetEntityStates = useCallback(
    async (entityKey: string, entityId?: number) => {
      dispatch({
        type: ActionTypes.RESET_ENTITY_STATES_REQUEST,
        payload: { entityKey, entityId },
      });
    },
    [dispatch],
  );

  const resetEntity = (entityKey: string) => {
    dispatch({
      type: ActionTypes.RESET_ENTITY_DATA_REQUEST,
      payload: { entityKey },
    });
  };

  const resetEntityFormData = (entityKey: string) => {
    dispatch({
      type: ActionTypes.RESET_ENTITY_FORM_DATA_REQUEST,
      payload: { entityKey },
    });
  };

  const onFailure = (action: string, entityKey: string, ex: any) => {
    if (action && entityKey) {
      dispatch({ type: action, payload: { entityKey } });
    }

    toast.error(ex.error || ex.e || ex.message);

    return ex;
  };

  const getEntityState = useCallback(
    (entityKey: string, entityId?: number) =>
      state[getStateName(entityKey, entityId)],
    [state],
  );

  /*
   * loadEntityviews
   * Return list of Views schema
   * params: entity key
   */
  const loadEntityViews = useCallback(
    async (entityKey: string) =>
      get(`/views/${entityKey}`).catch(ex => {
        toast.error(ex.message);
      }),
    [],
  );

  /*
   * loadEntitySchema
   * Return entity schema
   * params: entity key
   */
  const loadEntitySchema = useCallback(
    async (entityKey: string, entityId?: number) =>
      get(`/schema/${entityKey}${entityId ? `/${entityId}` : ''}`)
        .then(response => ({
          ...response,
          formValidation: buildSchemaValidation(response),
          draftValidation: buildSchemaValidation(response, {
            ignoreRequired: true,
          }),
        }))
        .catch(ex => {
          toast.error(ex.error);
        }),
    [],
  );

  /*
   * fetchEntityHookContent
   * Return entity schema
   * params: entity key
   */
  const fetchEntityHookContent = useCallback(
    async (hookId: string) =>
      get(`/hooks/${hookId}`)
        .then(response => response)
        .catch(ex => {
          toast.error(ex.message);
        }),
    [],
  );

  /*
   * getSchemaAndViewsByEntityName
   * description: Return the Schema & Views of an entity
   * params: entity name
   */
  const getSchemaAndViewsByEntityName = useCallback(
    async entityKey => {
      const [schema, views]: [
        IEntitySchemaDefinition,
        IJsonEntityView[],
      ] = await Promise.all([
        loadEntitySchema(entityKey),
        loadEntityViews(entityKey),
      ]);

      return {
        schema,
        views,
      };
    },
    [loadEntitySchema, loadEntityViews],
  );

  /*
   * fetchDataByEntityName
   * FetchAll: Return all entity records
   * params: entity name
   */
  const getDataByEntityName = useCallback(
    (
      entityKey,
      limit,
      page,
      sort,
      where = {},
      withRelated = [],
      groupBy = [],
      select,
    ) => {
      const hasGroupBy = groupBy && groupBy.length > 0;
      const pageParam = (!hasGroupBy && `&page=${page || 0}`) || '';
      return post(`/${entityKey}/search?limit=${limit || 25}${pageParam}`, {
        $withRelated: withRelated,
        $where: where,
        $sort: sort,
        $groupBy: groupBy,
        $select: select,
      });
    },
    [],
  );

  /*
   * fetchAllByEntityName
   * FetchAll: Return all entity records
   * params: entity name
   */
  const fetchAllByEntityName = useCallback(
    async (entityKey: string, searchRequest: SearchRequest) => {
      try {
        const { page, pageLimit } = searchRequest;

        dispatch({
          type: ActionTypes.FETCH_ALL_BY_ENTITY_KEY_REQUEST,
          payload: { entityKey },
        });

        const { schema, views } = await getSchemaAndViewsByEntityName(
          entityKey,
        );

        if (!schema) {
          console.error('schema is required, something went wrong.');
          return;
        }

        const view = getView(views, searchRequest);

        const {
          $select,
          $where,
          $sort,
          $groupBy,
          $withRelated,
        } = searchRequest.buildQuery(schema.filterFields, view);

        const data = await getDataByEntityName(
          entityKey,
          pageLimit,
          page,
          $sort,
          $where,
          $withRelated,
          $groupBy,
          $select,
        );

        dispatch({
          type: ActionTypes.FETCH_ALL_BY_ENTITY_KEY_SUCCESS,
          payload: {
            entityKey,
            data,
            schema,
            views,
            view,
          },
        });

        return data;
      } catch (e) {
        return onFailure(ActionTypes.FETCH_ENTITY_FAILURE, entityKey, e);
      }
    },
    [getDataByEntityName, getSchemaAndViewsByEntityName],
  );

  /*
   * fetchOneByType
   * FetchOne: Return one record detail by
   * params: entity name and id
   */
  const fetchOneByType = useCallback(
    async (entityKey: string, entityId?: number) => {
      dispatch({
        type: ActionTypes.FETCH_ONE_BY_ENTITY_KEY_REQUEST,
        payload: { entityKey, entityId },
      });

      const schema = await loadEntitySchema(entityKey, entityId);

      const data =
        entityId &&
        (await post(`/${entityKey}/${entityId}/detail`, {
          $select: schema.displayFields,
        }).catch(e =>
          onFailure(ActionTypes.FETCH_ENTITY_FAILURE, entityKey, e),
        ));

      dispatch({
        type: ActionTypes.FETCH_ONE_BY_ENTITY_KEY_SUCCESS,
        payload: {
          entityKey,
          entityId,
          data,
          schema,
        },
      });
    },
    [dispatch, loadEntitySchema],
  );

  const fetchCountRelatedEntities = useCallback(
    async (entityKey: string, entityId: number) => {
      const response = await post(
        `/${entityKey}/${entityId}/count-related-entities`,
        {
          tables: [
            '_entity_comment',
            '_entity_attachment',
            '_entity_task',
            '_entity_log',
          ],
        },
      );

      dispatch({
        type: ActionTypes.FETCH_COUNT_RELATED_BY_ENTITY_KEY_SUCCESS,
        payload: {
          entityKey,
          entityId,
          countRelatedEntities: response,
        },
      });
    },
    [],
  );

  const insertEntityAttachment = async (
    entityKey: string,
    data: any,
    entityId: number,
  ) => {
    dispatch({
      type: ActionTypes.CREATE_ENTITY_ATTACHMENT_REQUEST,
      payload: { entityKey, entityId },
    });

    return post(`/${entityKey}/${entityId}/attachment`, data, {
      'Content-Type': 'multipart/form-data',
    })
      .then(response => {
        dispatch({
          type: ActionTypes.CREATE_ENTITY_ATTACHMENT_SUCCESS,
          payload: {
            entityKey,
            entityId,
            response,
          },
        });

        return response;
      })
      .catch(e =>
        onFailure(ActionTypes.CREATE_ENTITY_ATTACHMENT_FAIL, entityKey, e),
      );
  };

  const deleteEntityAttachment = async (
    entityKey: string,
    entityId: number,
    attachmentId: number,
  ): Promise<boolean> => {
    dispatch({
      type: ActionTypes.DELETE_ENTITY_ATTACHMENT_REQUEST,
      payload: { entityKey, entityId },
    });

    return remove(`/${entityKey}/${entityId}/attachment/${attachmentId}`)
      .then(response => {
        dispatch({
          type: ActionTypes.DELETE_ENTITY_ATTACHMENT_SUCCESS,
          payload: {
            entityKey,
            entityId,
            response,
          },
        });

        return true;
      })
      .catch(e => {
        onFailure(ActionTypes.DELETE_ENTITY_ATTACHMENT_FAIL, entityKey, e);
        return false;
      });
  };

  const fetchEntityAttachments = async (
    entityKey: string,
    entityId: number,
  ) => {
    dispatch({
      type: ActionTypes.FETCH_ENTITY_ATTACHMENT_REQUEST,
      payload: { entityKey, entityId },
    });

    return get(`/${entityKey}/${entityId}/attachment`)
      .then(response => {
        dispatch({
          type: ActionTypes.FETCH_ENTITY_ATTACHMENT_SUCCESS,
          payload: {
            entityKey,
            entityId,
            response,
          },
        });
        return response;
      })
      .catch(e => onFailure(ActionTypes.FETCH_ENTITY_FAILURE, entityKey, e));
  };

  const fetchEntityHistories = useCallback(
    async (entityKey: string, entityId: number) => {
      dispatch({
        type: ActionTypes.FETCH_ENTITY_HISTORY_REQUEST,
        payload: { entityKey, entityId },
      });

      return get(`/${entityKey}/${entityId}/history`)
        .then(response => {
          dispatch({
            type: ActionTypes.FETCH_ENTITY_HISTORY_SUCCESS,
            payload: {
              entityKey,
              entityId,
              response,
            },
          });
          return response;
        })
        .catch(e => onFailure(ActionTypes.FETCH_ENTITY_FAILURE, entityKey, e));
    },
    [dispatch],
  );

  const fetchEntityLogs = useCallback(
    async (entityKey: string, entityId: number) => {
      dispatch({
        type: ActionTypes.FETCH_ENTITY_LOGS_REQUEST,
        payload: { entityKey, entityId },
      });

      return get(`/${entityKey}/${entityId}/logs`)
        .then((response: StorageObject[]) => {
          dispatch({
            type: ActionTypes.FETCH_ENTITY_LOGS_SUCCESS,
            payload: {
              entityKey,
              entityId,
              response: response.reverse(),
            },
          });
          return response;
        })
        .catch(e => onFailure(ActionTypes.FETCH_ENTITY_FAILURE, entityKey, e));
    },
    [dispatch],
  );

  const fetchEntityComments = async (entityKey: string, entityId: string) => {
    dispatch({
      type: ActionTypes.FETCH_ENTITY_COMMENT_REQUEST,
      payload: { entityKey, entityId },
    });

    return get(`/${entityKey}/${entityId}/comment`)
      .then(response => {
        dispatch({
          type: ActionTypes.FETCH_ENTITY_COMMENT_SUCCESS,
          payload: {
            entityKey,
            entityId,
            response,
          },
        });

        return response;
      })
      .catch(e => onFailure(ActionTypes.FETCH_ENTITY_FAILURE, entityKey, e));
  };

  const insertEntityComment = async (
    entityKey: string,
    data: any,
    entityId: number,
  ) => {
    dispatch({
      type: ActionTypes.CREATE_ENTITY_COMMENT_REQUEST,
      payload: { entityKey, entityId },
    });

    return post(`/${entityKey}/${entityId}/comment`, data)
      .then(response => {
        dispatch({
          type: ActionTypes.CREATE_ENTITY_COMMENT_SUCCESS,
          payload: {
            entityKey,
            entityId,
            response,
          },
        });

        return response;
      })
      .catch(e =>
        onFailure(ActionTypes.CREATE_ENTITY_COMMENT_FAIL, entityKey, e),
      );
  };

  const fetchEntityTasks = async (entityKey: string, entityId: string) => {
    dispatch({
      type: ActionTypes.FETCH_ENTITY_TASK_REQUEST,
      payload: { entityKey, entityId },
    });

    return get(`/${entityKey}/${entityId}/task`)
      .then(response => {
        dispatch({
          type: ActionTypes.FETCH_ENTITY_TASK_SUCCESS,
          payload: {
            entityKey,
            entityId,
            response,
          },
        });

        return response;
      })
      .catch(e => onFailure(ActionTypes.FETCH_ENTITY_FAILURE, entityKey, e));
  };

  const insertEntityTask = async (
    entityKey: string,
    entityId: number,
    data: any,
  ) => {
    dispatch({
      type: ActionTypes.CREATE_ENTITY_TASK_REQUEST,
      payload: { entityKey, entityId },
    });

    return post(`/${entityKey}/${entityId}/task`, data)
      .then(response => {
        dispatch({
          type: ActionTypes.CREATE_ENTITY_TASK_SUCCESS,
          payload: {
            entityKey,
            entityId,
            response,
          },
        });

        return response;
      })
      .catch(e => onFailure(ActionTypes.CREATE_ENTITY_TASK_FAIL, entityKey, e));
  };

  const updateEntityTask = async (
    entityKey: string,
    entityId: number,
    taskId: number,
    data: any,
  ) => {
    dispatch({
      type: ActionTypes.UPDATE_ENTITY_TASK_REQUEST,
      payload: { entityKey, entityId },
    });

    return put(`/${entityKey}/${entityId}/task/${taskId}`, data)
      .then(response => {
        dispatch({
          type: ActionTypes.UPDATE_ENTITY_TASK_SUCCESS,
          payload: {
            entityKey,
            entityId,
            response,
          },
        });

        return response;
      })
      .catch(e => onFailure(ActionTypes.UPDATE_ENTITY_TASK_FAIL, entityKey, e));
  };

  const deleteEntityTask = async (
    entityKey: string,
    entityId: number,
    taskId: number,
  ): Promise<boolean> => {
    dispatch({
      type: ActionTypes.DELETE_ENTITY_TASK_REQUEST,
      payload: { entityKey, entityId },
    });

    return remove(`/${entityKey}/${entityId}/task/${taskId}`)
      .then(response => {
        dispatch({
          type: ActionTypes.DELETE_ENTITY_TASK_SUCCESS,
          payload: {
            entityKey,
            entityId,
            response,
          },
        });

        return true;
      })
      .catch(e => {
        onFailure(ActionTypes.DELETE_ENTITY_TASK_FAIL, entityKey, e);
        return false;
      });
  };

  const insertFileUpload = async (
    entityKey: string,
    name: string,
    data: any,
    onUploadProgress: (event: any) => void,
  ) => {
    dispatch({
      type: ActionTypes.CREATE_ENTITY_FILE_UPLOAD_REQUEST,
      payload: { entityKey },
    });
    return post(
      `/${entityKey}/storage/${name}`,
      data,
      { 'Content-Type': 'multipart/form-data' },
      onUploadProgress,
    )
      .then(response => {
        dispatch({
          type: ActionTypes.CREATE_ENTITY_FILE_UPLOAD_SUCCESS,
          payload: {
            entityKey,
            response,
          },
        });
        return response;
      })
      .catch(e =>
        onFailure(ActionTypes.CREATE_ENTITY_FILE_UPLOAD_FAIL, entityKey, e),
      );
  };

  /*
   * create
   * store: Create one entity
   * params: entity name, form data
   */
  const create = async (entityKey: string, data: any) => {
    dispatch({
      type: ActionTypes.CREATE_ENTITY_REQUEST,
      payload: { entityKey },
    });

    return post(`/${entityKey}`, data)
      .then(response => {
        dispatch({
          type: ActionTypes.CREATE_ENTITY_SUCCESS,
          payload: {
            entityKey,
            response,
          },
        });

        return response;
      })
      .catch(e => onFailure(ActionTypes.CREATE_ENTITY_FAIL, entityKey, e));
  };

  /*
   * edit
   * update: Update one entity
   * params: entity name, form data, entity id
   */
  const edit = useCallback(
    async (entityKey: string, data: any, entityId: number) => {
      dispatch({
        type: ActionTypes.UPDATE_ENTITY_REQUEST,
        payload: { entityKey, entityId },
      });

      return put(`/${entityKey}/${entityId}`, data)
        .then(response => {
          dispatch({
            type: ActionTypes.UPDATE_ENTITY_SUCCESS,
            payload: {
              entityKey,
              entityId,
              response,
            },
          });

          return response;
        })
        .catch(e => onFailure(ActionTypes.UPDATE_ENTITY_FAIL, entityKey, e));
    },
    [dispatch],
  );

  /*
   * remove
   * Remove one entity
   * params: entity name, entity id
   */
  const deleteEntity = useCallback(
    async (entityKey: string, entityId: number) => {
      dispatch({
        type: ActionTypes.DELETE_ENTITY_REQUEST,
        payload: { entityKey },
      });

      return remove(`/${entityKey}/${entityId}`)
        .then(response => {
          dispatch({
            type: ActionTypes.DELETE_ENTITY_SUCCESS,
            payload: {
              entityKey,
              entityId,
              response,
            },
          });

          return response;
        })
        .catch(e => onFailure(ActionTypes.DELETE_ENTITY_FAIL, entityKey, e));
    },
    [dispatch],
  );

  /** ********* WORKFLOW FUNCTIONS ********** */

  /**
   * Move to an specific workflow step
   *
   * @param {string} entityKey entity key
   * @param {number} entityId entity id
   * @param {object} data entity form data
   */
  const moveStep = async (
    entityKey: string,
    data: any,
    entityId: number,
    actionKey: string,
  ) => {
    dispatch({
      type: ActionTypes.ENTITY_NEXT_STEP_REQUEST,
      payload: { entityKey, entityId },
    });

    return post(`/workflow/${entityKey}/${entityId}/move/${actionKey}`, data)
      .then(response => {
        dispatch({
          type: ActionTypes.ENTITY_NEXT_STEP_SUCCESS,
          payload: {
            entityKey,
            entityId,
            response,
          },
        });

        return response;
      })
      .catch(e => onFailure(ActionTypes.ENTITY_NEXT_STEP_FAIL, entityKey, e));
  };

  /**
   * Return Step Workflow
   *
   * @param {string} entityKey entity key
   * @param {number} entityId entity id
   * @param {object} data entity form data
   */
  const returnStep = async (entityKey: string, data: any, entityId: number) => {
    dispatch({
      type: ActionTypes.ENTITY_RETURN_STEP_REQUEST,
      payload: { entityKey, entityId },
    });

    return post(`/workflow/${entityKey}/${entityId}/return`, data)
      .then(response => {
        dispatch({
          type: ActionTypes.ENTITY_RETURN_STEP_SUCCESS,
          payload: {
            entityKey,
            entityId,
            response,
          },
        });

        return response;
      })
      .catch(e => onFailure(ActionTypes.ENTITY_RETURN_STEP_FAIL, entityKey, e));
  };

  /**
   * Reject Workflow
   *
   * @param {string} entityKey entity key
   * @param {number} entityId entity id
   * @param {object} data entity form data
   */
  const reject = async (entityKey: string, data: any, entityId: number) => {
    dispatch({
      type: ActionTypes.ENTITY_REJECT_REQUEST,
      payload: { entityKey, entityId },
    });

    return post(`/workflow/${entityKey}/${entityId}/reject`, data)
      .then(response => {
        dispatch({
          type: ActionTypes.ENTITY_REJECT_SUCCESS,
          payload: {
            entityKey,
            entityId,
            response,
          },
        });

        return response;
      })
      .catch(e => onFailure(ActionTypes.ENTITY_REJECT_FAIL, entityKey, e));
  };

  /**
   * Take ownership Workflow
   *
   * @param {string} entityKey entity key
   * @param {number} entityId entity id
   * @param {object} data entity form data
   */
  const takeOwnership = async (
    entityKey: string,
    data: any,
    entityId: number,
  ) => {
    dispatch({
      type: ActionTypes.ENTITY_TAKE_OWNERSHIP_REQUEST,
      payload: { entityKey, entityId },
    });

    return post(`/workflow/${entityKey}/${entityId}/take-ownership`, data)
      .then(response => {
        dispatch({
          type: ActionTypes.ENTITY_TAKE_OWNERSHIP_SUCCESS,
          payload: {
            entityKey,
            entityId,
            response,
          },
        });

        return response;
      })
      .catch(e =>
        onFailure(ActionTypes.ENTITY_TAKE_OWNERSHIP_FAIL, entityKey, e),
      );
  };

  /**
   * Approve Workflow
   *
   * @param {string} entityKey entity key
   * @param {number} entityId entity id
   * @param {object} data entity form data
   */
  const approve = async (entityKey: string, data: any, entityId: number) => {
    dispatch({
      type: ActionTypes.ENTITY_APPROVE_REQUEST,
      payload: { entityKey, entityId },
    });

    return post(`/workflow/${entityKey}/${entityId}/approve`, data)
      .then(response => {
        dispatch({
          type: ActionTypes.ENTITY_APPROVE_SUCCESS,
          payload: {
            entityKey,
            entityId,
            response,
          },
        });

        return response;
      })
      .catch(e => onFailure(ActionTypes.ENTITY_APPROVE_FAIL, entityKey, e));
  };

  /** ********* END WORKFLOW FUNCTIONS ********** */

  const openDocument = useCallback(
    async (
      entityKey: string,
      documentId: number,
      searchRequest: SearchRequest,
    ) => {
      try {
        const { schema, view } = getEntityState(entityKey);
        const { $where, $withRelated, $sort } = searchRequest.buildQuery(
          schema.filterFields,
          view,
        );

        window.open(
          `/api/${entityKey}/doc/${documentId}?${serializeQuery({
            criterion: JSON.stringify({ $where, $withRelated, $sort }),
            limit: isEmpty($where) ? 1000 : undefined,
          })}`,
          '_blank',
        );
      } catch (e) {
        return onFailure(ActionTypes.EXPORT_FAIL, entityKey, e);
      }
    },
    [getEntityState],
  );

  const exportToExcel = useCallback(
    async (entityKey: string, searchRequest: SearchRequest) => {
      try {
        const { schema, view } = getEntityState(entityKey);
        const { $where, $withRelated, $sort } = searchRequest.buildQuery(
          schema.filterFields,
          view,
        );

        dispatch({
          type: ActionTypes.EXPORT_REQUEST,
          payload: { entityKey },
        });

        const response = await download(
          `/${entityKey}/export?${isEmpty($where) ? 'limit=1000' : ''}`,
          { $where, $withRelated, $sort },
        );

        downloadFile(
          `${moment().format('YYYYMMDDTHHmmss')}-${entityKey}.xlsx`,
          response,
        );

        dispatch({
          type: ActionTypes.EXPORT_SUCCESS,
          payload: {
            entityKey,
            response,
          },
        });

        return response;
      } catch (e) {
        return onFailure(ActionTypes.EXPORT_FAIL, entityKey, e);
      }
    },
    [getEntityState],
  );

  const actions: EntityContextActions = {
    resetEntityStates,
    resetEntity,
    resetEntityFormData,
    fetchAllByEntityName,
    fetchOneByType,
    fetchCountRelatedEntities,
    fetchEntityHookContent,
    fetchEntityAttachments,
    insertEntityAttachment,
    deleteEntityAttachment,
    fetchEntityHistories,
    fetchEntityLogs,
    fetchEntityComments,
    fetchEntityTasks,
    insertEntityTask,
    insertEntityComment,
    updateEntityTask,
    deleteEntityTask,
    insertFileUpload,
    loadEntitySchema,
    create,
    edit,
    deleteEntity,
    exportToExcel,
    openDocument,
    // workflow functions
    returnStep,
    reject,
    takeOwnership,
    approve,
    moveStep,
    getEntityState,
  };

  return (
    <EntityContext.Provider value={{ ...actions, state }}>
      {children}
    </EntityContext.Provider>
  );
};

EntityContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { useEntityContext };

export default EntityContextProvider;
