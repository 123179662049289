import cx from 'classnames';
import PropTypes from 'prop-types';

const FileIcon = ({ type, size }) => {
  const icon = (className: string) => (
    <i className={cx('far', className, { [size]: size })} />
  );

  const icons = {
    'application/pdf': icon('fa-file-pdf'),
    'image/jpeg': icon('fa-file-image'),
    'image/png': icon('fa-file-image'),
    'image/gif': icon('fa-file-image'),
    'image/svg+xml': icon('fa-file-image'),
    'video/mpeg': icon('fa-file-video'),
    'video/x-msvideo': icon('fa-file-video'),
    'application/msword': icon('fa-file-word'),
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': icon(
      'fa-file-excel',
    ),
  };

  return icons[type] || icon('fa-file');
};

FileIcon.propTypes = {
  type: PropTypes.string,
  size: PropTypes.any,
};

FileIcon.defaultProps = {
  type: '',
  size: '',
};

export default FileIcon;
