import { DATA_ISO_REGEX } from 'constants/regex';

const getSortPath = path => {
  const segments = path.split('.');
  const result =
    segments.slice(0, segments.length - 1).join(':') +
    (segments.length > 1 ? '.' : '') +
    segments[segments.length - 1];

  return result;
};

const isValidISOString = (value: any) => {
  if (typeof value !== 'string') {
    return false;
  }
  return !!value.match(DATA_ISO_REGEX);
};

export { getSortPath, isValidISOString };
