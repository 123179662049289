import { Controller, useFormContext } from 'react-hook-form';
import MonacoEditor from 'react-monaco-editor';

import { FieldItem } from '@april9/stack9-sdk';
import cx from 'classnames';

import FieldLabel from './FieldLabel';

const languageValueParser = (language: string | undefined, value: any) => {
  if (!value) {
    return undefined;
  }

  /** if you are implenting new language, it's required to define it in config-overrides.js */
  switch (language) {
    case 'json':
      return JSON.stringify(value, null, 4);
    default:
      return value;
  }
};

export type MonacoEditorFieldProps = {
  name: string;
  fieldItem: FieldItem;
  defaultValue?: any;
  monacoOptions?: any;
};

const MonacoEditorField = ({
  name,
  fieldItem,
  defaultValue,
  monacoOptions,
  ...props
}: MonacoEditorFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { ui_component_options, description } = fieldItem;

  if (!ui_component_options) {
    throw new Error('ui_component_options is required');
  }

  const { readOnly, language } = ui_component_options;

  const options = {
    automaticLayout: true,
    readOnly,
    name,
    ...monacoOptions,
  };

  return (
    <FieldLabel fieldItem={fieldItem}>
      <div
        data-cy={name}
        className={cx('border', {
          'border-danger': errors[name],
          'is-invalid': errors[name],
        })}
      >
        <Controller
          control={control}
          name={name}
          defaultValue={languageValueParser(language, defaultValue)}
          render={({ field }) => (
            <MonacoEditor
              options={options}
              height="400"
              language={language}
              {...field}
              {...props}
            />
          )}
        />
      </div>

      {description && (
        <small className="form-text text-muted">{description}</small>
      )}
    </FieldLabel>
  );
};
export default MonacoEditorField;
