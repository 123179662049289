import './EntityHistoryList.scss';
import { IEntitySchemaDefinition } from '@april9/stack9-sdk';

import EntityHistoryListItem from '../EntityHistoryListItem/EntityHistoryListItem';

type Props = {
  histories: any[];
  schema: IEntitySchemaDefinition;
};

const EntityHistoryList = ({ histories, schema }: Props) => {
  if (!histories?.length) {
    return <h6>there is no history to show</h6>;
  }

  return (
    <div className="EntityHistoryList">
      {histories.map(history => (
        <div className="history" key={history.id}>
          {schema && (
            <EntityHistoryListItem history={history} schema={schema} />
          )}
        </div>
      ))}
    </div>
  );
};

export default EntityHistoryList;
