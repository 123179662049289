import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { camelCase, chain } from 'lodash';

import CustomUIComponent from 'components/CustomUIComponent/CustomUIComponent';
import MetaTags from 'components/MetaTags/MetaTags';

import LayoutWithMenu from '../components/Layout/LayoutWithMenu';
import { AppContext } from '../contexts/AppContext';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const CustomUIPage = () => {
  const { pageComponent, appKey } = useParams<{
    pageComponent: string;
    appKey: string;
  }>();
  const {
    state: { menuItems },
  } = useContext(AppContext);

  // From testing_custom_page (page slug following the current s9 structure)
  // To TestingCustomPage (component name)
  const componentName = capitalizeFirstLetter(
    camelCase(pageComponent.toLowerCase()),
  );

  const menuItem = chain(menuItems)
    .flatten()
    .map('item_groups')
    .flatten()
    .map('items')
    .flatten()
    .filter({ page: pageComponent })
    .first()
    .value();

  return (
    <LayoutWithMenu className="p-0">
      <>
        {menuItem && <MetaTags appKey={appKey} entityName={menuItem.label} />}
        <CustomUIComponent componentName={componentName} />
      </>
    </LayoutWithMenu>
  );
};

export default CustomUIPage;
