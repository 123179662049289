import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactSelect from 'react-select';

import {
  FilterFormValue,
  LabelValue,
  useMapSourceLabelValue,
  Utils,
} from '@april9/stack9-react';
import { ComponentOptions, RelationshipOptions } from '@april9/stack9-sdk';
import { post } from 'core/fetch';
import PropTypes from 'prop-types';
import { useDebounce } from 'use-debounce';

type Props = {
  name: string;
  defaultValue?: FilterFormValue;
  options?: Array<any>;
  limit?: number;
  uiComponentOptions: ComponentOptions;
  relationshipOptions: RelationshipOptions;
};

const MultiDropDown = ({
  name,
  options: propOptions,
  defaultValue,
  uiComponentOptions,
  relationshipOptions,
  limit,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<Array<LabelValue>>([]);
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    labelPropName,
    valuePropName,
  } = Utils.form.getComponentOptionsLabelValue(uiComponentOptions);

  const { mapLabelValue } = useMapSourceLabelValue(
    valuePropName,
    labelPropName,
  );

  const fetchRelatedOptions = async (
    searchText?: string,
    fieldToQuery?: string,
  ) => {
    if (!relationshipOptions) return;

    setLoading(true);

    const filterWhere = relationshipOptions.filter_where;

    const items = await post(
      `/${relationshipOptions.related_entity}/search?limit=${limit}`,
      {
        $where: {
          ...(filterWhere ?? {}),
          ...(searchText && fieldToQuery
            ? { [fieldToQuery]: { $like: `%${searchText}%` } }
            : {}),
          _is_deleted: false,
        },
      },
    );

    setOptions(items.map(mapLabelValue));
    setLoading(false);
  };

  useEffect(() => {
    if (propOptions) {
      setOptions(propOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propOptions]);

  const [inputText, setInputText] = useState();
  const [searchText] = useDebounce(inputText, 500);

  useEffect(() => {
    if (searchText || searchText === '') {
      fetchRelatedOptions(searchText, labelPropName);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <ReactSelect
          {...field}
          styles={{
            control: provided => ({
              ...provided,
              borderColor: errors[name] ? 'red' : '#ced4da',
              minHeight: '3rem',
            }),
            menuPortal: base => ({ ...base, zIndex: 9999 }),
          }}
          onInputChange={setInputText}
          onMenuOpen={() => {
            if (!inputText) {
              fetchRelatedOptions();
            }
          }}
          menuPortalTarget={document.body}
          noOptionsMessage={() => (loading ? 'Loading...' : 'No Options')}
          options={options}
          id={name}
          closeMenuOnSelect={false}
          isMulti
        />
      )}
    />
  );
};

MultiDropDown.propTypes = {
  options: PropTypes.array,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  limit: PropTypes.number,
  uiComponentOptions: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  relationshipOptions: PropTypes.shape({
    related_entity: PropTypes.string.isRequired,
    filter_where: PropTypes.object,
  }),
};

MultiDropDown.defaultProps = {
  options: [],
  defaultValue: [],
  limit: 30,
  uiComponentOptions: undefined,
  relationshipOptions: undefined,
};

export default MultiDropDown;
