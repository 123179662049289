import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { IEntitySchemaDefinition, StorageObject } from '@april9/stack9-sdk';
import cx from 'classnames';
import { post } from 'core/fetch';
import { isObject, isNil, isEqual, keyBy } from 'lodash';
import moment from 'moment';
import { isValidISOString } from 'utils/stringUtils';

import EntityLogListItem from 'components/EntityLogListItem/EntityLogListItem';

import './EntityLogList.scss';

type Props = {
  entityKey: string;
  entityId: number;
  logs: StorageObject[];
  schema: IEntitySchemaDefinition;
};

const EntityLogList = ({ entityKey, entityId, logs, schema }: Props) => {
  const { register, handleSubmit, watch } = useForm();

  const [showVersionDiff, setShowVersionDiff] = useState(false);
  const [selectedLogsContent, setSelectedLogsContent] = useState<any[]>([]);

  const fieldKeys = useMemo(
    () =>
      schema.formLayout.fieldsets
        .map(fieldset =>
          fieldset.rows.map(row => row.columns.map(column => column.fieldKey)),
        )
        .flat(2),
    [schema.formLayout.fieldsets],
  );

  if (!logs?.length) {
    return <h6>there is no logs to show</h6>;
  }

  const compareButtonAvailable = Object.values(watch()).some(
    checked => checked,
  );

  const generateDateKey = (date: Date) =>
    moment(date).format(`YYYYMMDDHHmmssSSS`);

  const safeParse = (value: any) => {
    if (typeof value === 'number' || typeof value === 'boolean') {
      return <span>{value.toString()}</span>;
    }

    if (!value) return <span>(empty)</span>;

    if (isObject(value)) {
      return <pre>{JSON.stringify(value, null, '\t')}</pre>;
    }

    if (isValidISOString(value)) {
      return (
        <span>
          {moment(value, moment.ISO_8601, true).local().format('LLL')}
        </span>
      );
    }

    return <span>{value}</span>;
  };

  const fieldValueChanged = (selectedLogsContent: any[], field: string) =>
    selectedLogsContent.some((val, i, arr) => {
      const fieldValue = val[field];
      const firstFieldValue = arr[0][field];

      if (isNil(fieldValue) && isNil(firstFieldValue)) {
        return false;
      }

      if (isValidISOString(fieldValue) || isValidISOString(firstFieldValue)) {
        return (
          moment(fieldValue).toISOString() !==
          moment(firstFieldValue).toISOString()
        );
      }

      return !isEqual(firstFieldValue, fieldValue);
    });

  const onSubmit = async (values: { [key: string]: [checked: boolean] }) => {
    if (showVersionDiff) {
      setShowVersionDiff(false);
      return;
    }

    const selectedLogsIds = Object.keys(values).filter(key => values[key]);

    if (selectedLogsIds.length < 1) {
      return;
    }

    const selectedLogsFilePath = logs.reduce((acc: string[], log) => {
      if (selectedLogsIds.includes(generateDateKey(log.createdOn))) {
        return [...acc, log.blob];
      }
      return acc;
    }, []);

    const selectedLogsContent = await post(`/${entityKey}/${entityId}/logs`, {
      filePaths: selectedLogsFilePath,
    });

    const sortedSelectedLogsContent = selectedLogsContent.sort((log1, log2) =>
      log1._updated_at > log2._updated_at ? 1 : -1,
    );

    setSelectedLogsContent(sortedSelectedLogsContent);
    setShowVersionDiff(true);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="EntityLogList">
        <div className={cx('body', { list: !showVersionDiff })}>
          {!showVersionDiff &&
            logs.map((log: StorageObject) => {
              const key = generateDateKey(log.createdOn);
              return (
                <div className="log" key={key}>
                  {schema && (
                    <div className="custom-checkbox pl-4 ml-2">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={key}
                        {...register(key)}
                        disabled={log.metadata.action === 'deleted'}
                      />
                      <label className="custom-control-label" htmlFor={key}>
                        <EntityLogListItem log={log} />
                      </label>
                    </div>
                  )}
                </div>
              );
            })}

          {showVersionDiff && (
            <table
              className="EntityLogTable table table-bordered m-0 mt-2"
              data-cy="logs-table"
            >
              <tbody>
                <tr>
                  <th>Updated by</th>
                  {selectedLogsContent.map(log => (
                    <td key={log._updated_at}>
                      <span>{log.userEmail || 'Unknown'}</span>
                    </td>
                  ))}
                </tr>
                <tr>
                  <th>Updated at</th>
                  {selectedLogsContent.map(log => (
                    <td key={log._updated_at}>
                      <span>
                        {moment(log._updated_at).local().format('LLL')}
                      </span>
                    </td>
                  ))}
                </tr>

                {fieldKeys.map(fieldKey => {
                  const mappedField = keyBy(
                    Object.values(schema.fields),
                    'key',
                  );

                  const fieldItem = mappedField[fieldKey];

                  if (!fieldItem) {
                    return <></>;
                  }

                  const mappedFieldKey = fieldItem.key;

                  return (
                    <tr
                      key={mappedFieldKey}
                      className={cx({
                        highlight: fieldValueChanged(
                          selectedLogsContent,
                          mappedFieldKey,
                        ),
                      })}
                    >
                      <th>{mappedField[fieldKey].label}</th>
                      {selectedLogsContent.map(log => (
                        <td key={log._updated_at}>
                          {safeParse(log[mappedFieldKey])}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
        <div className="footer">
          <button
            type="submit"
            className="btn btn-primary btn-sm mb-4 mr-0"
            data-cy="compare-versions"
            disabled={!compareButtonAvailable}
          >
            {showVersionDiff ? 'Back' : 'Compare versions'}
          </button>
        </div>
      </div>
    </form>
  );
};

export default EntityLogList;
