/* eslint-disable import/no-unresolved */

import { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { createRequires } from '@paciolan/remote-component/dist/createRequires';
import { createUseRemoteComponent } from '@paciolan/remote-component/dist/hooks/useRemoteComponent';
import fetch from 'core/fetch';

import { AppContext } from 'contexts/AppContext';
import { useDialogManager } from 'contexts/DialogManagerContext';

const requires = createRequires({
  // eslint-disable-next-line global-require
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  react: require('react'),
});
const useRemoteComponent = createUseRemoteComponent({ requires });

interface IProps {
  componentName: string;
}

const CustomUIComponent: FC<IProps> = ({ componentName, ...props }) => {
  const dialogManagerContext = useDialogManager();
  const appContext = useContext(AppContext);

  const url = `/custom-ui-component/${componentName}.js`;

  const componentProps = {
    dialogManagerContext,
    appContext,
    fetch,
    toast,
    Router: {
      Link,
    },
    ...props,
  };

  const [loading, err, Component] = useRemoteComponent(url);

  if (loading) {
    return <div>Loading {componentName}...</div>;
  }

  if (err != null) {
    return (
      <div className="mx-5 mt-5">
        <p>
          Error to render custom field <strong>{componentName}</strong>:{` `}
          {err.toString()}.
        </p>

        <p>Please check if this is a valid component name.</p>
      </div>
    );
  }

  return <Component {...componentProps} />;
};

export default CustomUIComponent;
