import { createRef, useContext, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useFormContext, Controller } from 'react-hook-form';

import { Utils } from '@april9/stack9-react';
import { FieldItem } from '@april9/stack9-sdk';
import cx from 'classnames';
import moment from 'moment';

import { AppContext } from 'contexts/AppContext';

import FieldLabel from './FieldLabel';

export type DatePickerFieldProps = {
  name: string;
  fieldItem: FieldItem;
  defaultValue?: any;
};

const DatePickerField = ({
  name,
  fieldItem,
  defaultValue,
  ...props
}: DatePickerFieldProps) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const dateInputRef = createRef<any>();
  const { ui_component_options, description, placeholder } = fieldItem;
  const { time, readOnly } = ui_component_options || {};

  const {
    state: { settings },
  } = useContext(AppContext);

  useEffect(() => {
    if (!defaultValue) {
      return;
    }

    const date = moment.utc(defaultValue);
    if (date.isValid()) {
      setValue(name, date.toDate());
    }
  }, [setValue, defaultValue, name]);

  return (
    <FieldLabel fieldItem={fieldItem}>
      <div className="date-picker-container">
        <Controller
          control={control}
          name={name}
          defaultValue={
            defaultValue ? moment.utc(defaultValue).toDate() : undefined
          }
          render={({ field }) => (
            <DatePicker
              onChange={field.onChange}
              onBlur={field.onBlur}
              selected={moment(field.value).isValid() ? field.value : undefined}
              className={cx('form-control', {
                'is-invalid': errors[name],
              })}
              dateFormat={Utils.date.normaliseFormat(
                settings.dateFormat,
                time ? settings.timeFormat : undefined,
                { moment: false },
              )}
              readOnly={readOnly}
              timeFormat={settings.timeFormat}
              todayButton="Today"
              showTimeInput={time}
              placeholderText={placeholder}
              autoComplete="off"
              name={field.name}
              ref={dateInputRef}
              {...props}
            />
          )}
        />

        {description && (
          <small className="form-text text-muted">{description}</small>
        )}
        <span
          role="button"
          className="form-inline d-flex justify-content-center align-items-center date-picker-icon"
          onClick={() => dateInputRef.current.setFocus()}
          onKeyPress={e => {
            if (e.keyCode === 13) dateInputRef.current.setFocus();
          }}
          tabIndex={0}
        >
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </FieldLabel>
  );
};

export default DatePickerField;
