import { useContext, useEffect, useCallback, useMemo } from 'react';
import { NavDropdown, Dropdown, ButtonGroup, Row } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FilterFormKeyField } from '@april9/stack9-react';
import { IJsonEntityView } from '@april9/stack9-sdk';
import cx from 'classnames';
import { kebabCase, isEmpty } from 'lodash';
import { SearchRequest } from 'models/SearchRequest';
import { prepareSortFromObject } from 'utils/entity';
import { parseFormKeyFieldToKeyValue } from 'utils/filters';
import { getViewGroupBy } from 'utils/formUtils';
import { Messages } from 'utils/messages';

import EntityViews from 'components/Entity/Views/EntityViews';
import Filters from 'components/Filters/Filters';
import LayoutWithMenu from 'components/Layout/LayoutWithMenu';
import MetaTags from 'components/MetaTags/MetaTags';
import ENTITY from 'constants/Entity';
import { useDialogManager } from 'contexts/DialogManagerContext';
import { EntityContext } from 'contexts/EntityContext';

import EntityPageLoader from '../../components/Loaders/EntityPageLoader';

import s from './Entity.module.scss';

const Entity = () => {
  const {
    fetchAllByEntityName,
    resetEntityStates,
    exportToExcel,
    openDocument,
    getEntityState,
  } = useContext(EntityContext);

  const history = useHistory();
  const { search } = useLocation();

  const { appKey, entityKey, id: entityId, operation } = useParams<{
    appKey: string;
    entityKey: string;
    operation: string;
    id: string;
  }>();

  const entityState = getEntityState(entityKey);

  if ((window as any).Cypress) {
    (window as any).entityAction = entityState;
    (window as any).entityState = entityState;
  }

  const entitySchema = entityState?.schema;

  const searchRequest = useMemo(
    () =>
      !search
        ? new SearchRequest()
        : new SearchRequest().fromQuerystring(search),
    [search],
  );

  const availablePrintableDocs = entitySchema?.printableDocuments?.filter(
    p => p.use_in_grid,
  );

  const { openDialog } = useDialogManager();

  const submitFilter = (data: FilterFormKeyField) => {
    const formValues = parseFormKeyFieldToKeyValue(
      data,
      entitySchema!.filterFields,
    );

    const searchQuery = searchRequest
      .updateFilterFormValues(formValues)
      .setPage(0);

    history.push({ search: searchQuery.toQuerystring() });
  };

  const selectView = (view: IJsonEntityView, limit: number) => {
    const sort = prepareSortFromObject(view.viewOptions!.defaultSort);

    const searchQuery = searchRequest
      .setLimit(limit)
      .setView(view.key)
      .setSort(sort)
      .setGroupBy(getViewGroupBy(view))
      .setPage(0);

    history.push({ search: searchQuery.toQuerystring() });
  };

  const fetchListData = useCallback(() => {
    fetchAllByEntityName(entityKey, searchRequest);
  }, [entityKey, fetchAllByEntityName, searchRequest]);

  const handleExport = useCallback(() => {
    exportToExcel(entityKey, searchRequest);
  }, [entityKey, exportToExcel, searchRequest]);

  const handleDocument = useCallback(
    (documentId: number) => {
      openDocument(entityKey, documentId, searchRequest);
    },
    [entityKey, openDocument, searchRequest],
  );

  const handlePage = async ({ limit, page }) => {
    const request = searchRequest.setLimit(limit).setPage(page);

    history.push({ search: request.toQuerystring() });
  };

  const handleSorting = async $sort => {
    const sort = prepareSortFromObject($sort);
    const request = searchRequest.setSort(sort);

    history.push({ search: request.toQuerystring() });
  };

  const afterSaveCallback = useCallback(
    async ({ id }) => {
      toast.success(Messages.EntitySaved);

      fetchListData();

      resetEntityStates(entityKey, id);
    },
    [fetchListData, resetEntityStates, entityKey],
  );

  const afterDeleteCallback = useCallback(
    async ({ id }) => {
      toast.success(Messages.EntityDeleted);

      fetchListData();

      resetEntityStates(entityKey, id);
    },
    [entityKey, fetchListData, resetEntityStates],
  );

  const openDialogByOperation = operation => {
    if (operation === 'edit' && entityId) {
      openDialog({
        entityKey,
        entityId: parseInt(entityId, 10),
        afterSaveCallback,
        afterDeleteCallback,
      });
    } else if (operation === 'create') {
      openDialog({
        entityKey,
        afterSaveCallback,
        afterDeleteCallback,
      });
    }
  };

  useEffect(() => {
    // TODO: DO we really need this?
    resetEntityStates(entityKey);
  }, [resetEntityStates, entityKey]);

  useEffect(fetchListData, [fetchListData]);

  useEffect(() => {
    const hasValidOperation =
      operation && (operation === 'edit' || operation === 'create');

    if (hasValidOperation) openDialogByOperation(operation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading =
    !entitySchema || !entityState?.listData || entityState.isLoading;

  return (
    <LayoutWithMenu>
      <div
        className={cx('px-3 px-lg-5 py-3 py-lg-5 d-flex', s.root)}
        data-cy="entity-list-container"
      >
        {(isLoading && <EntityPageLoader className="w-100" />) || (
          <>
            {entitySchema?.name && (
              <MetaTags appKey={appKey} entityName={entitySchema.name} />
            )}

            <div className="w-100 d-flex flex-column">
              <Row
                data-cy="entity-table-wrapper"
                className={cx('mb-4 align-items-center', s.header)}
              >
                <div className={s.leftActions}>
                  <div className="d-inline-flex align-items-center">
                    <h3
                      className={cx('text-primary mb-0', {
                        'mr-4': !!entityState?.views?.length,
                      })}
                      data-cy="page-title"
                    >
                      {entitySchema?.pluralName}
                    </h3>

                    {!!entityState?.views?.length && entityState?.view && (
                      <NavDropdown
                        data-cy="dropdown-entity-view"
                        className={s.entityViewNav}
                        title={
                          <span>
                            {ENTITY.VIEWS_ICONS[entityState.view.viewType]}
                            <span className="ml-2">
                              {entityState.view.label}
                            </span>
                          </span>
                        }
                        id="nav-entity-views"
                        onSelect={eventKey => {
                          const view = entityState.views.find(
                            v => v.label === eventKey,
                          );

                          if (!view)
                            return console.error(
                              `View was not found: eventKey: ${eventKey}`,
                            );

                          switch (view.viewType) {
                            case ENTITY.VIEWS.LIST: {
                              selectView(view, SearchRequest.DefaltQueryLimit);
                              break;
                            }

                            case ENTITY.VIEWS.MAP: {
                              selectView(view, SearchRequest.DefaultMapLimit);
                              break;
                            }

                            case ENTITY.VIEWS.TIMELINE:
                            case ENTITY.VIEWS.KANBAN: {
                              selectView(view, SearchRequest.DefaultGroupLimit);
                              break;
                            }

                            default:
                              throw new Error('ViewType is not implemented');
                          }
                        }}
                      >
                        {entityState.views.map(view => (
                          <NavDropdown.Item
                            key={kebabCase(view.label)}
                            eventKey={view.label}
                          >
                            {ENTITY.VIEWS_ICONS[view.viewType]} {view.label}
                          </NavDropdown.Item>
                        ))}
                      </NavDropdown>
                    )}
                  </div>

                  {entitySchema && !isEmpty(entitySchema.filterFields) && (
                    <Filters
                      filterFields={entitySchema.filterFields}
                      workflowDefinition={entitySchema.workflow?.definition}
                      submitFilter={submitFilter}
                      filterFormValues={searchRequest.filterFormValues}
                    />
                  )}
                </div>

                <div className={s.rightActions}>
                  <button
                    type="button"
                    className="btn btn-link text-primary border-0 font-weight-bolder p-0"
                    data-cy="refresh"
                    onClick={fetchListData}
                  >
                    <i className="fa fa-refresh" />
                  </button>

                  <button
                    type="button"
                    className={cx(
                      'btn btn-primary btn-lg font-weight-bolder',
                      s.actionButton,
                    )}
                    disabled={entityState?.formIsBlocked}
                    data-cy="create"
                    onClick={e => {
                      e.preventDefault();

                      openDialog({
                        entityKey,
                        afterSaveCallback,
                        afterDeleteCallback,
                      });
                    }}
                  >
                    <i className="fa fa-plus mr-2" />
                    Create
                  </button>

                  <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle data-cy="btn-export" variant="light">
                      <i className="far fa-file-export mr-2" /> Export
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handleExport}>
                        <i className="fa fa-file-excel mr-1" /> Export CSV
                      </Dropdown.Item>
                      {!!availablePrintableDocs?.length && <Dropdown.Divider />}
                      {availablePrintableDocs?.map(doc => (
                        <Dropdown.Item
                          disabled={isLoading}
                          key={doc.id}
                          onClick={() => handleDocument(doc.id)}
                        >
                          <i className="fa fa-file-pdf mr-1" /> {doc.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Row>

              {!isLoading &&
                entitySchema &&
                entityState &&
                entityState.view && (
                  <EntityViews
                    currentPage={searchRequest.page}
                    defaultSort={searchRequest.sort}
                    entityKey={entityKey}
                    listData={entityState.listData}
                    pageLimit={searchRequest.pageLimit}
                    schema={entityState.schema}
                    selectedView={entityState.view}
                    total={entityState.total}
                    afterDeleteCallback={afterDeleteCallback}
                    afterSaveCallback={afterSaveCallback}
                    handlePage={handlePage}
                    handleSorting={handleSorting}
                  />
                )}
            </div>
          </>
        )}
      </div>
    </LayoutWithMenu>
  );
};

export default Entity;
