import { useState, useContext } from 'react';

import { FieldItem, S9Query } from '@april9/stack9-sdk';
import { get } from 'core/fetch';
import { filter, set } from 'lodash';
import buildRelatedGridOptions from 'utils/buildRelatedGridOptions';

import { AgGrid } from 'components/ThirdLibraries';
import { AppContext } from 'contexts/AppContext';
import { useDialogManager } from 'contexts/DialogManagerContext';

import FieldLabel from './FieldLabel';

type Props = {
  defaultValue: any;
  fieldItem: FieldItem;
  entityKey: string;
  entityId: number;
};

const Grid = ({ defaultValue, fieldItem, entityKey, entityId }: Props) => {
  const {
    state: { settings, selectedApp },
  } = useContext(AppContext);

  const {
    ui_component_options: componentOptions,
    relationshipOptions,
  } = fieldItem;

  const { relationshipField, allowCreate } = componentOptions!;
  const {
    related_entity,
    name: relation_name,
    filter_where,
  } = relationshipOptions!;
  const [gridItems, setGridItems] = useState(defaultValue);
  const { openDialog } = useDialogManager();

  const entitySchema = fieldItem.schema;

  if (!entitySchema) {
    return <div />;
  }

  const {
    columnDefs,
    defaultColDef,
    suppressHorizontalScroll,
    frameworkComponents,
  } = buildRelatedGridOptions(
    componentOptions!.gridSettings.agGrid,
    entitySchema,
    settings,
    related_entity,
  );

  const convertFilter = (filterWhere: S9Query | undefined) => {
    const query = {};

    if (!filterWhere) return query;

    for (const [key, value] of Object.entries(filterWhere)) {
      set(query, key, value);
    }
    return query;
  };

  const afterActionCallback = async () => {
    const response = await get(`/${entityKey}/${entityId}`);
    setGridItems(response[relation_name]);
  };

  return (
    <FieldLabel
      fieldItem={fieldItem}
      actions={
        <div className="text-right">
          {allowCreate && (
            <button
              className="btn btn-outline-secondary btn-sm"
              type="button"
              disabled={!entityId || componentOptions?.readOnly}
              title={
                !entityId
                  ? `Please create the ${entityKey} first, to start adding ${fieldItem.label}`
                  : ''
              }
              onClick={() => {
                openDialog({
                  entityKey: related_entity,
                  afterSaveCallback: afterActionCallback,
                  afterDeleteCallback: afterActionCallback,
                  foreignKey: {
                    fieldName: relationshipField,
                    fieldValue: entityId,
                  },
                });
              }}
            >
              <i className="fa fa-plus" />
              {` `}Add {fieldItem.label}
            </button>
          )}

          <a
            className="btn btn-link btn-sm"
            href={`/apps/${selectedApp}/${related_entity}${
              entityId ? `?${relationshipField}=${entityId}` : ''
            }`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-external-link" />
          </a>
        </div>
      }
    >
      <AgGrid
        rowData={
          relationshipOptions?.filter_where && gridItems
            ? filter(gridItems, convertFilter(filter_where))
            : gridItems || []
        }
        columnDefs={columnDefs}
        onRowSelected={event => {
          if (!event.node.selected) return;

          openDialog({
            entityKey: related_entity,
            entityId: parseInt(event.node.data.id, 10),
            afterSaveCallback: afterActionCallback,
            afterDeleteCallback: afterActionCallback,
            foreignKey: {
              fieldName: relationshipField,
              fieldValue: entityId,
            },
          });

          // Unselect row selected, so I can re-select the same again
          event.node.setSelected(false);
        }}
        frameworkComponents={frameworkComponents}
        defaultColDef={defaultColDef}
        suppressHorizontalScroll={suppressHorizontalScroll}
      />

      {fieldItem.description && (
        <small className="form-text text-muted">{fieldItem.description}</small>
      )}

      {gridItems.length >= 100 && (
        <small className="form-text text-danger">
          This grid might not show all records. it's been limited to 100
          records.
        </small>
      )}
    </FieldLabel>
  );
};

Grid.defaultProps = {
  defaultValue: '',
  entityKey: '',
  entityId: undefined,
};

export default Grid;
