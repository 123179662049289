import { useEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { Messages } from 'utils/messages';

import { IronBody } from 'components/Layout';
import { useEntityContext } from 'contexts/EntityContext';

import CommentsLoader from '../../Loaders/CommentsLoader'; // TODO

import s from './EntityTask.module.scss';

const EntityTask = ({ entityKey, entityId }) => {
  const [loading, setLoading] = useState(false);
  const [displayTaskField, setDisplayTaskField] = useState(false);
  const {
    formState: { errors },
    setError,
    register,
    handleSubmit,
  } = useForm();

  const {
    getEntityState,
    fetchEntityTasks,
    insertEntityTask,
    updateEntityTask,
    deleteEntityTask,
    fetchCountRelatedEntities,
  } = useEntityContext();

  const entityState = getEntityState(entityKey, entityId);

  const tasks = useMemo(() => entityState?.tasks || [], [entityState?.tasks]);
  const completedTasks = useMemo(
    () => tasks.filter(task => task.is_completed) || [],
    [tasks],
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await fetchEntityTasks(entityKey, entityId);
      } catch (error) {
        toast.error((error as string) || 'Error trying to fetch tasks');
      }
      setLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDisplayTaskField = () => {
    setDisplayTaskField(!displayTaskField);
  };

  const cancel = () => {
    setDisplayTaskField(false);
  };

  const onSubmit = async (formData, e) => {
    if (!formData.description.trim()) {
      return setError('description', {
        type: 'manual',
        message: 'This field is required',
      });
    }

    const { id } = await insertEntityTask(entityKey, entityId, formData);

    if (!id) {
      return toast.error('Error trying to create a task');
    }

    toast.success(Messages.TaskAdded);

    fetchEntityTasks(entityKey, entityId);
    e.target.reset();
    toggleDisplayTaskField();
    fetchCountRelatedEntities(entityKey, entityId);
  };

  const onSelectTask = async taskId => {
    const task = entityState?.tasks.find(task => task.id === taskId);
    if (!task) return;

    await updateEntityTask(entityKey, entityId, taskId, {
      is_completed: !task.is_completed,
    });

    fetchEntityTasks(entityKey, entityId);
  };

  const onDelete = async taskId => {
    const success = await deleteEntityTask(entityKey, entityId, taskId);

    if (success) {
      toast.success(Messages.TaskDeleted);

      fetchEntityTasks(entityKey, entityId);
      fetchCountRelatedEntities(entityKey, entityId);
    }
  };

  const percentageOfCompleted =
    (completedTasks.length > 0 ? completedTasks.length / tasks.length : 0) *
    100;

  return (
    <IronBody>
      <div className="EntityTask">
        {!displayTaskField ? (
          <div
            role="presentation"
            onClick={toggleDisplayTaskField}
            data-cy="show-task-field"
          >
            <div className="form-group">
              <input
                className="form-control"
                role="button"
                id="task"
                name="task"
                type="text"
                placeholder="Add an item..."
              />
            </div>
          </div>
        ) : (
          <div className="media task pt-0">
            <div className="media-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group mb-0">
                  <input
                    className={cx('form-control', {
                      'is-invalid': errors.task,
                    })}
                    id="task"
                    autoFocus
                    {...register('description', {
                      required: 'This field is required',
                    })}
                    maxLength={255}
                    autoComplete="off"
                  />

                  {errors.description && (
                    <span className="text-danger">
                      {errors.description.message}
                    </span>
                  )}
                </div>

                <div className="mt-2">
                  <button
                    className="btn btn-link text-primary"
                    type="submit"
                    data-cy="save-task"
                  >
                    Add
                  </button>

                  <button
                    className="btn btn-link text-primary ml-3"
                    type="submit"
                    disabled={entityState?.formIsBlocked}
                    onClick={e => {
                      e.preventDefault();
                      cancel();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        <div className="progress mb-1">
          <div
            className={cx('progress-bar', s.progressBar)}
            style={{ width: `${percentageOfCompleted}%` }}
            role="progressbar"
            aria-valuenow={completedTasks.length}
            aria-valuemin={percentageOfCompleted}
            aria-valuemax={100}
          >
            <span
              style={{
                color:
                  percentageOfCompleted > 50
                    ? 'var(--primary-extra-light)'
                    : 'var(--primary)',
              }}
            >
              {completedTasks.length} / {entityState?.tasks?.length}
            </span>
          </div>
        </div>

        {!loading ? (
          <ul className="list-group">
            {entityState?.tasks?.map(task => (
              <li key={task.id} className={cx('list-group-item', s.listItem)}>
                <input
                  className="form-check-input mt-0"
                  type="checkbox"
                  checked={task.is_completed}
                  onChange={() => onSelectTask(task.id)}
                  id={task.id}
                />

                <div
                  className={s.detail}
                  data-cy="task-message"
                  onClick={() => onSelectTask(task.id)}
                >
                  <label
                    htmlFor={task.id}
                    className={cx({ [s.lineThrough]: task.is_completed })}
                  >
                    {task.description}
                  </label>

                  <button
                    className="btn btn-sm btn-link"
                    data-cy="task-delete"
                    onClick={() => onDelete(task.id)}
                    type="button"
                  >
                    <i className="far fa-trash-alt text-primary" />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <CommentsLoader />
        )}
      </div>
    </IronBody>
  );
};

EntityTask.propTypes = {
  entityKey: PropTypes.string.isRequired,
  entityId: PropTypes.number.isRequired,
};

export default EntityTask;
