import { Controller, useFormContext } from 'react-hook-form';

import { FieldItem } from '@april9/stack9-sdk';
import { Editor } from '@tinymce/tinymce-react';
import cx from 'classnames';

import { useEntityContext } from 'contexts/EntityContext';

import FieldLabel from './FieldLabel';

export type HtmlFieldProps = {
  name: string;
  fieldItem: FieldItem;
  defaultValue?: any;
  entityKey: string;
};

const HtmlField = ({
  name,
  defaultValue,
  fieldItem,
  entityKey,
}: HtmlFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { insertFileUpload } = useEntityContext();

  const { ui_component_options, description } = fieldItem;

  const readOnly = ui_component_options?.readOnly || false;

  const images_upload_handler = async (
    blobInfo,
    success,
    failure,
    progress,
  ) => {
    try {
      const formData = new FormData();
      formData.append('files', blobInfo.blob());

      const response = await insertFileUpload(
        entityKey,
        name,
        formData,
        event => {
          progress(Math.round((95 * event.loaded) / event.total));
        },
      );

      if (!response?.url) {
        failure(`Image upload error`, { remove: true });
      }

      success(response.url);
    } catch (error) {
      failure(`Image upload error: ${error}`, { remove: true });
    }
  };

  return (
    <FieldLabel fieldItem={fieldItem}>
      <div data-cy={name} className={cx({ 'is-invalid': errors[name] })}>
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={({ field }) => (
            <Editor
              value={field.value}
              disabled={readOnly}
              onEditorChange={field.onChange}
              onBlur={field.onBlur}
              init={{
                height: 250,
                forced_root_block: false,
                paste_data_images: true,
                images_upload_handler: images_upload_handler,
                content_css: '/htmlEditor.css',
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | image | code',
              }}
            />
          )}
        />

        {description && (
          <small className="form-text text-muted">{description}</small>
        )}
      </div>
    </FieldLabel>
  );
};

export default HtmlField;
