import { useState, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import cx from 'classnames';
import PropTypes from 'prop-types';

import FileIcon from 'components/FileIcon/FileIcon';
import { useEntityContext } from 'contexts/EntityContext';

import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';
import FieldLabel from '../FieldLabel';

import s from './FileField.module.scss';

const validImageTypes = [
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
];

const maxSizeAllowedBytes = 104857600; // 100 MB

const FileField = ({ name, entityKey, entityId, defaultValue, fieldItem }) => {
  const [showConfirmDeleteModal, setConfirmShowDeleteModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState<string | undefined>();
  const [entityValue, setEntityValue] = useState(defaultValue || undefined);
  const inputFile = useRef<HTMLInputElement>(null);

  const {
    accept,
    readOnly = false,
    maxSizeBytes,
  } = fieldItem.ui_component_options;

  const { insertFileUpload } = useEntityContext();

  const {
    register,
    unregister,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const onChange = async e => {
    const file: File = e.target.files[0];

    if (!file) {
      setError('File is invalid.');
      return;
    }

    if (file.size > maxSizeBytes || file.size > maxSizeAllowedBytes) {
      setError('File is too large.');
      return;
    }

    const data = new FormData();
    data.append('files', file);

    const previousValue = getValues(name);

    setValue(name, undefined);
    setProgress(0);

    const response = await insertFileUpload(entityKey, name, data, event => {
      setProgress(Math.round((95 * event.loaded) / event.total));
    });

    setProgress(100);

    if (!response) {
      setValue(name, previousValue);
      return false;
    }

    setValue(name, JSON.stringify(response));
    setEntityValue(response);

    return true;
  };

  const deleteFile = async () => {
    if (inputFile.current) {
      inputFile.current.value = '';
    }

    setValue(name, '');
    setEntityValue(undefined);
    setConfirmShowDeleteModal(false);
  };

  useEffect(() => {
    register(name);
    setValue(name, defaultValue ? JSON.stringify(defaultValue) : '');

    return () => {
      unregister(name);
    };
  }, [register, unregister, name, setValue, defaultValue]);

  return (
    <FieldLabel fieldItem={fieldItem}>
      {!readOnly && !entityValue && (
        <div className="d-flex">
          <label htmlFor={name} className="btn btn-outline-primary mr-3 mb-0">
            Upload file
          </label>

          <input
            id={name}
            type="file"
            className={cx(s.input, { 'is-invalid': errors[name] })}
            ref={inputFile}
            onChange={onChange}
            accept={accept}
          />

          <div>
            {error && <small className="text-danger">{error}</small>}

            {fieldItem.description && (
              <div>
                <small className="form-text text-muted">
                  {fieldItem.description}
                </small>
              </div>
            )}

            {progress > 1 && progress < 100 && (
              <div className="progress">
                <div
                  className="progress-bar progress-bar-info progress-bar-striped"
                  role="progressbar"
                  aria-valuenow={progress}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{ width: `${progress}%` }}
                >
                  {progress}%
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {entityValue && (
        <div className="d-flex">
          {(validImageTypes.includes(entityValue.mimetype) && (
            <div className={s.thumbnailImage}>
              <img src={entityValue.url} alt={entityValue.name} />
            </div>
          )) || (
            <div className={s.fileIcon}>
              <FileIcon type={entityValue.mimetype} />
            </div>
          )}

          <a
            href={
              entityValue.isPublic
                ? entityValue.url
                : `/api/${entityKey}/${entityId}/storage/${name}`
            }
            className={s.imageLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {entityValue.name}
          </a>

          {!readOnly && (
            <div className="float-right">
              <button
                type="button"
                className="btn btn-sm"
                onClick={() => setConfirmShowDeleteModal(true)}
              >
                <span className="fa fa-minus-circle text-danger" />
              </button>
            </div>
          )}
        </div>
      )}

      {showConfirmDeleteModal && (
        <ConfirmationModal
          title="Delete confirmation"
          body="Are you sure you want to delete this file?"
          show={showConfirmDeleteModal}
          handleClose={() => setConfirmShowDeleteModal(false)}
          confirmAction={deleteFile}
          confirmType="danger"
        />
      )}
    </FieldLabel>
  );
};

FileField.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  entityKey: PropTypes.string.isRequired,
  entityId: PropTypes.number,
  fieldItem: PropTypes.shape({
    placeholder: PropTypes.string,
    description: PropTypes.string,
    ui_component_options: PropTypes.shape({
      accept: PropTypes.string,
      readOnly: PropTypes.bool,
      maxSizeBytes: PropTypes.number,
    }),
  }).isRequired,
};

FileField.defaultProps = {
  defaultValue: '',
  entityId: 0,
};

export default FileField;
