import { useFormContext } from 'react-hook-form';

import { FieldItem } from '@april9/stack9-sdk';
import cx from 'classnames';

import FieldLabel from './FieldLabel';

export type NumericFieldProps = {
  name: string;
  fieldItem: FieldItem;
  defaultValue?: any;
};

const NumericField = ({ name, defaultValue, fieldItem }: NumericFieldProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const { placeholder, ui_component_options } = fieldItem;
  const readOnly = ui_component_options?.readOnly || false;

  return (
    <FieldLabel fieldItem={fieldItem}>
      <input
        type="number"
        className={cx('form-control', { 'is-invalid': errors[name] })}
        placeholder={placeholder}
        id={name}
        readOnly={readOnly}
        defaultValue={defaultValue}
        {...register(name)}
      />

      {fieldItem.description && (
        <small className="form-text text-muted">{fieldItem.description}</small>
      )}
    </FieldLabel>
  );
};

export default NumericField;
