import { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactSelect from 'react-select';

import {
  LabelValue,
  useMapSourceLabelValue,
  Utils,
} from '@april9/stack9-react';
import { FieldItem } from '@april9/stack9-sdk';
import cx from 'classnames';
import { post } from 'core/fetch';
import { useDebounce } from 'use-debounce';

import { useDialogManager } from 'contexts/DialogManagerContext';
import { useEntityContext } from 'contexts/EntityContext';

import FieldLabel from './FieldLabel';

export type SingleDropdownProps = {
  name: string;
  fieldItem: FieldItem;
  defaultValue?: Array<any>;
  options?: Array<any>;
  limit?: number;
  data?: any; // TODO: do we really need this? if so, we need that in the multi dropdown as well right? Could we get from the react-hooks context?
};

const SingleDropDown = ({
  name,
  options: propOptions,
  defaultValue,
  fieldItem,
  limit = 20,
  data,
}: SingleDropdownProps) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<Array<LabelValue>>([]);
  const { resetEntityStates } = useEntityContext();

  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const { getDialog, openDialog } = useDialogManager();

  const dialog = getDialog();
  const foreignKey = dialog?.params?.foreignKey;

  const {
    ui_component_options,
    placeholder,
    relationshipOptions,
    description,
  } = fieldItem;

  const readOnly = ui_component_options?.readOnly || false;

  const {
    labelPropName,
    valuePropName,
  } = Utils.form.getComponentOptionsLabelValue(ui_component_options);

  const { mapLabelValue } = useMapSourceLabelValue(
    valuePropName,
    labelPropName,
  );

  const filterWhere = Utils.form.parseWhereVariables(
    relationshipOptions?.filter_where,
    data,
  );

  // Fetch to populate the dropdown with related entities
  const fetchRelatedOptions = async (
    searchText?: string,
    fieldToQuery?: string,
  ) => {
    if (!relationshipOptions) return;
    setLoading(true);

    try {
      const items = await post(
        `/${relationshipOptions.related_entity}/search?limit=${limit}`,
        {
          $where: {
            ...(filterWhere ?? {}),
            ...(searchText && fieldToQuery
              ? { [fieldToQuery]: { $like: `%${searchText}%` } }
              : {}),
            _is_deleted: false,
          },
        },
      );

      setOptions(items.map(mapLabelValue));

      setLoading(false);
    } catch (err) {
      throw new Error(err);
    }
  };

  const fetchSelectedOption = async (
    entity: string,
    id: string,
    setValueOptions?: any,
  ) => {
    const response = await post(`/${entity}/search`, {
      $select: [valuePropName, labelPropName],
      $where: {
        id,
      },
    });

    if (!response[0]) return;

    setValue(name, mapLabelValue(response[0]), setValueOptions);
  };

  useEffect(() => {
    if (propOptions) {
      setOptions(propOptions.map(mapLabelValue));
    }

    if (foreignKey && foreignKey.fieldName === name && relationshipOptions) {
      fetchSelectedOption(
        relationshipOptions.related_entity,
        foreignKey.fieldValue,
        { shouldDirty: false },
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [inputText, setInputText] = useState();
  const [searchText] = useDebounce(inputText, 500);

  const transformedDefaultValue = useMemo(
    () => (defaultValue ? mapLabelValue(defaultValue) : undefined),
    [defaultValue, mapLabelValue],
  );

  useEffect(() => {
    if (searchText || searchText === '') {
      fetchRelatedOptions(searchText, labelPropName);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const selectedValue = getValues(name);

  return (
    <FieldLabel
      fieldItem={fieldItem}
      actions={
        <div>
          {selectedValue?.value && (
            <button
              type="button"
              className="btn btn-link mr-1 btn-sm"
              data-cy="edit-related"
              onClick={() => {
                const entityKey = relationshipOptions?.related_entity;
                const entityId = parseInt(selectedValue.value, 10);

                if (!entityKey) {
                  return;
                }

                openDialog({
                  entityKey,
                  entityId,
                  afterSaveCallback: () => {
                    fetchSelectedOption(entityKey, entityId.toString());
                    resetEntityStates(entityKey, entityId);
                  },
                });
              }}
            >
              {readOnly && <i className="fa fa-eye" title="View record" />}
              {!readOnly && <i className="fa fa-pencil" title="Edit record" />}
            </button>
          )}
          {!readOnly && (
            <button
              type="button"
              data-cy="single-drop-down-create-new"
              className="btn btn-link btn-sm"
              onClick={() => {
                const entityKey = relationshipOptions?.related_entity;
                openDialog({
                  entityKey,
                  afterSaveCallback: () => {
                    if (entityKey) {
                      resetEntityStates(entityKey);
                    }
                  },
                });
              }}
            >
              <i className="fa fa-plus" title="Create new record" />
            </button>
          )}
        </div>
      }
    >
      <Controller
        control={control}
        name={name}
        defaultValue={transformedDefaultValue}
        render={({ field }) => (
          <ReactSelect
            {...field}
            styles={{
              control: provided => ({
                ...provided,
                borderColor: errors[name] ? 'red' : '#ced4da',
                minHeight: '3rem',
              }),
              menuPortal: base => ({ ...base, zIndex: 9999 }),
            }}
            onInputChange={setInputText}
            onMenuOpen={() => {
              if (!inputText) {
                fetchRelatedOptions();
              }
            }}
            className={cx('single-dropdown', { 'is-invalid': errors[name] })}
            menuPortalTarget={document.body}
            placeholder={placeholder}
            noOptionsMessage={() => (loading ? 'Loading...' : 'No Options')}
            options={options}
            id={name}
            closeMenuOnSelect={true}
            isClearable
            isDisabled={foreignKey?.fieldName === name || readOnly}
          />
        )}
      />

      {description && (
        <small className="form-text text-muted">{description}</small>
      )}
    </FieldLabel>
  );
};

SingleDropDown.defaultProps = {
  value: undefined,
  options: [],
  limit: 30,
  defaultValue: undefined,
  data: {},
};

export default SingleDropDown;
