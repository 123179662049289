import { useState, useEffect } from 'react';
import { Tab, Nav } from 'react-bootstrap';

import cx from 'classnames';
import { FormMode } from 'core/formMode';

import WorkflowStepper from 'components/WorkflowStepper/WorkflowStepper';
import { useEntityContext } from 'contexts/EntityContext';

import EntityActionBar from '../EntityActionBar/EntityActionBar';
import EntityAttachment from '../EntityAttachment/EntityAttachment';
import EntityComment from '../EntityComment/EntityComment';
import EntityForm from '../EntityForm/EntityForm';
import EntityHistory from '../EntityHistory/EntityHistory';
import EntityTask from '../EntityTask/EntityTask';

import s from './EntityMain.module.scss';

type Props = {
  entityId?: number;
  entityKey: string;
  modalLevel: number;
};

const EntityMain = ({ entityId, entityKey, modalLevel }: Props) => {
  const [actionBarRef, setActionBarRef] = useState(null);
  const { getEntityState, ...actions } = useEntityContext();

  const entityState = getEntityState(entityKey, entityId);

  useEffect(() => {
    if (entityId || !entityState?.schema) {
      actions.fetchOneByType(entityKey, entityId);
    }

    if (entityId) {
      actions.fetchCountRelatedEntities(entityKey, entityId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createOrEditMode = entityId ? FormMode.edit : FormMode.create;
  const formMode = entityState?.formData?._workflow_outcome
    ? FormMode.readonly
    : createOrEditMode;

  const shouldDisplayComments = entityState?.schema?.allow_comments;
  const shouldDisplayTasks = entityState?.schema?.allow_tasks;

  return (
    <>
      <EntityActionBar onRefLoaded={el => setActionBarRef(el)} />

      {entityState?.schema?.hasWorkflow && formMode !== FormMode.create && (
        <div
          className={cx({
            'my-3': !entityState?.schema?.workflow?.outcomeReason,
            'mb-3': entityState?.schema?.workflow?.outcomeReason,
          })}
        >
          <WorkflowStepper workflow={entityState?.schema?.workflow} />
        </div>
      )}

      <Tab.Container id="entity-tabs" defaultActiveKey="details">
        <Nav variant="tabs" className={s.tab}>
          <Nav.Item>
            <Nav.Link className="m-0" eventKey="details">
              DETAILS
            </Nav.Link>
          </Nav.Item>

          {formMode !== FormMode.create && (
            <>
              <Nav.Item>
                <Nav.Link
                  className="m-0"
                  eventKey="attachments"
                  data-cy="tab-attachments"
                >
                  <i className="fas fa-paperclip" />
                  <span>
                    {` `}(
                    {entityState?.countRelatedEntities?._entity_attachment || 0}
                    )
                  </span>
                </Nav.Link>
              </Nav.Item>

              {shouldDisplayComments && (
                <Nav.Item>
                  <Nav.Link
                    className="m-0"
                    eventKey="comment"
                    data-cy="tab-comments"
                  >
                    <i className="fas fa-comments-alt" />
                    <span>
                      {` `}(
                      {entityState?.countRelatedEntities?._entity_comment || 0})
                    </span>
                  </Nav.Link>
                </Nav.Item>
              )}

              {shouldDisplayTasks && (
                <Nav.Item>
                  <Nav.Link className="m-0" eventKey="task" data-cy="tab-tasks">
                    <i className="fas fa-tasks" />
                    <span>
                      {` `}(
                      {entityState?.countRelatedEntities?._entity_task || 0})
                    </span>
                  </Nav.Link>
                </Nav.Item>
              )}

              <Nav.Item>
                <Nav.Link
                  className="m-0"
                  eventKey="history"
                  data-cy="tab-history"
                >
                  <i className="fas fa-history" />
                  <span>
                    {` `}({entityState?.countRelatedEntities?._entity_log || 0})
                  </span>
                </Nav.Link>
              </Nav.Item>
            </>
          )}
        </Nav>

        <Tab.Content
          // variant="pills" todo: tscheck
          className={s.tabContent}
        >
          <Tab.Pane eventKey="details">
            <EntityForm
              entityState={entityState}
              formMode={formMode}
              entityActionBarRef={actionBarRef}
              entityKey={entityKey}
              entityId={entityId}
              modalLevel={modalLevel}
            />
          </Tab.Pane>

          {formMode !== FormMode.create && entityId && (
            <>
              <Tab.Pane eventKey="attachments" mountOnEnter>
                <EntityAttachment entityKey={entityKey} entityId={entityId} />
              </Tab.Pane>

              {shouldDisplayComments && (
                <Tab.Pane eventKey="comment" mountOnEnter unmountOnExit>
                  <EntityComment entityKey={entityKey} entityId={entityId} />
                </Tab.Pane>
              )}

              {shouldDisplayTasks && (
                <Tab.Pane eventKey="task" mountOnEnter unmountOnExit>
                  <EntityTask entityKey={entityKey} entityId={entityId} />
                </Tab.Pane>
              )}

              <Tab.Pane eventKey="history" mountOnEnter unmountOnExit>
                <EntityHistory entityKey={entityKey} entityId={entityId} />
              </Tab.Pane>
            </>
          )}
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default EntityMain;
