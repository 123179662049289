import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { Messages } from 'utils/messages';
import { getInitialLetter } from 'utils/text';

import { IronBody } from 'components/Layout';
import Moment from 'components/ThirdLibraries/Moment/Moment';
import { useEntityContext } from 'contexts/EntityContext';

import './EntityComment.scss';
import CommentsLoader from '../../Loaders/CommentsLoader';

const EntityComment = ({ entityKey, entityId }) => {
  const [loading, setLoading] = useState(false);
  const [insertLoading, setInsertLoading] = useState(false);
  const [displayCommentField, setDisplayCommentField] = useState(false);
  const {
    formState: { errors },
    register,
    setValue,
    handleSubmit,
  } = useForm();

  const {
    getEntityState,
    fetchEntityComments,
    insertEntityComment,
    fetchCountRelatedEntities,
  } = useEntityContext();

  const entityState = getEntityState(entityKey, entityId);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await fetchEntityComments(entityKey, entityId);
      } catch (error) {
        toast.error(error);
      }
      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDisplayCommentField = () => {
    setDisplayCommentField(!displayCommentField);
  };

  const cancel = () => {
    setDisplayCommentField(false);
  };

  const onSubmit = async formData => {
    setInsertLoading(true);
    const { id } = await insertEntityComment(entityKey, formData, entityId);
    setInsertLoading(false);

    if (id) {
      toast.success(Messages.CommentAdded);

      fetchEntityComments(entityKey, entityId);

      setValue('comment', '');
      toggleDisplayCommentField();

      fetchCountRelatedEntities(entityKey, entityId);
    }
  };

  const avatar = createdBy => (
    <div className="circle circle-gray rounded-circle mr-3">
      <strong>{getInitialLetter(createdBy?.first_name)}</strong>
    </div>
  );

  return (
    <IronBody>
      <div className="EntityComment">
        {!displayCommentField ? (
          <div
            role="presentation"
            onClick={() => toggleDisplayCommentField()}
            data-cy="show-comment-field"
          >
            <div className="form-group">
              <input
                className="form-control"
                role="button"
                id="insert-comment"
                type="text"
                name="insert-comment"
                placeholder="Add a comment..."
                // onChange={null} TODO: tscheck
              />
            </div>
          </div>
        ) : (
          <div className="media comment pt-0">
            <div className="media-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group mb-0">
                  <textarea
                    className={cx('form-control', {
                      'is-invalid': errors.comment,
                    })}
                    id="comment"
                    rows={5}
                    autoFocus
                    maxLength={500}
                    {...register('comment', { required: true, maxLength: 500 })}
                  />

                  {errors.comment && (
                    <span className="text-danger">
                      {errors.comment?.message}
                    </span>
                  )}
                </div>

                <div className="mt-2">
                  <button
                    className="btn btn-link text-primary"
                    type="submit"
                    data-cy="save-comment"
                    // disabled={meta.isLoading}
                  >
                    Save Comment
                  </button>

                  <button
                    className="btn btn-link text-primary ml-3"
                    type="submit"
                    disabled={entityState?.formIsBlocked}
                    onClick={e => {
                      e.preventDefault();
                      cancel();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {insertLoading && <CommentsLoader rows={1} />}

        {!loading ? (
          entityState?.comments?.map(comment => (
            <div className="comment" key={comment.id}>
              <div className="media">
                {avatar(comment.created_by)}
                <div className="media-body">
                  <h6 className="font-weight-bold">
                    {comment.created_by
                      ? comment.created_by.first_name
                      : 'Unknown'}
                    {` `}
                    <small>
                      <Moment fromNow>{comment._created_at}</Moment>
                    </small>
                  </h6>
                  <div data-cy="comment-message">{comment.comment}</div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <CommentsLoader />
        )}
      </div>
    </IronBody>
  );
};

EntityComment.propTypes = {
  entityKey: PropTypes.string.isRequired,
  entityId: PropTypes.number.isRequired,
};

export default EntityComment;
