import { ReactNode } from 'react';

import { FieldItem } from '@april9/stack9-sdk';
import cx from 'classnames';

export type FieldLabelProps = {
  fieldItem: FieldItem;
  children: ReactNode;
  actions?: ReactNode;
};

const FieldLabel = ({ fieldItem, children, actions }: FieldLabelProps) => {
  const { key, label, validation_rules, ui_component_options } = fieldItem;

  return (
    <div className="form-group">
      <div
        className={cx(
          'd-flex justify-content-between align-items-baseline align-middle',
        )}
        style={{ flexDirection: 'row' }}
      >
        <label htmlFor={key}>
          {`${label}:`}
          <span className="text-danger">
            {validation_rules?.required &&
              !ui_component_options?.readOnly &&
              ' *'}
          </span>
        </label>

        {actions}
      </div>
      {children}
    </div>
  );
};

export default FieldLabel;
