import { useContext, useEffect } from 'react';
import Helmet from 'react-helmet';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Routes from 'routes';

import DialogManager from 'components/DialogManager/DialogManager';

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

import { useAuthContext } from 'contexts/AuthContext';

import { AppContext } from './contexts/AppContext';

const App = () => {
  const {
    state: { settings },
    actions: { fetchApps, fetchMe },
  } = useContext(AppContext);

  const {
    state: { authenticatedUser },
  } = useAuthContext();

  useEffect(() => {
    void fetchApps();
  }, [fetchApps]);

  useEffect(() => {
    if (authenticatedUser) fetchMe();
  }, [authenticatedUser, fetchMe]);

  return (
    <>
      <Helmet>
        {settings.favicon && <link rel="icon" href={settings.favicon} />}
      </Helmet>
      <Router>
        <Routes />
        <ToastContainer hideProgressBar />
        <DialogManager />
      </Router>
    </>
  );
};

App.propTypes = {};

export default App;
