import { Controller, useFormContext } from 'react-hook-form';
import ReactSelect from 'react-select';

import { FilterFormValue } from '@april9/stack9-react';
import { WorkflowStep } from '@april9/stack9-sdk';

import { useDeepCompareMemo } from 'hooks/useDeepCompare';

type Props = {
  name: string;
  steps: Array<WorkflowStep>;
  defaultValue: FilterFormValue;
  labelForFinalised: string;
  labelForRejected: string;
};

const WorkflowStepField = ({
  name,
  defaultValue,
  steps,
  labelForFinalised,
  labelForRejected,
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const options = useDeepCompareMemo(
    () => [
      ...steps.map(workflowStep => ({
        label: workflowStep.name,
        value: workflowStep.order,
      })),
      {
        label: labelForFinalised,
        value: -1,
      },
      {
        label: labelForRejected,
        value: -2,
      },
    ],

    [labelForFinalised, labelForRejected, steps],
  );

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field }) => {
        return (
          <ReactSelect
            {...field}
            styles={{
              control: provided => ({
                ...provided,
                borderColor: errors[name] ? 'red' : '#ced4da',
                minHeight: '3rem',
              }),
              menuPortal: base => ({ ...base, zIndex: 9999 }),
            }}
            isClearable
            menuPortalTarget={document.body}
            options={options}
            id={name}
            closeMenuOnSelect={false}
            isMulti
          />
        );
      }}
    />
  );
};

WorkflowStepField.defaultProps = {
  defaultValue: '',
  labelForFinalised: 'Approved',
  labelForRejected: 'Rejected',
};

export default WorkflowStepField;
