import { useFormContext } from 'react-hook-form';

import { FieldItem } from '@april9/stack9-sdk';
import cx from 'classnames';

type Props = {
  name: string;
  fieldItem: FieldItem;
  entityKey: string;
  defaultValue: any;
  modalLevel: number;
};

const Checkbox = ({
  name,
  defaultValue,
  entityKey,
  fieldItem,
  modalLevel,
}: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const readOnly = fieldItem.ui_component_options?.readOnly || false;
  const id = `${entityKey}-checkbox-${name}-${modalLevel}`;

  return (
    <div className="form-group">
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          id={id}
          className={cx('custom-control-input', {
            'is-invalid': errors[name],
          })}
          defaultChecked={defaultValue}
          disabled={readOnly}
          {...register(name)}
        />
        <label className="custom-control-label" htmlFor={id}>
          {fieldItem.label}
        </label>

        {fieldItem.description && (
          <small className="form-text text-muted">
            {fieldItem.description}
          </small>
        )}
      </div>
    </div>
  );
};

export default Checkbox;
