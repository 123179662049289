import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactSelect from 'react-select';

import { FieldItem } from '@april9/stack9-sdk';
import cx from 'classnames';

import FieldLabel from './FieldLabel';

type Props = {
  name: string;
  defaultValue: any;
  fieldItem: FieldItem;
  data: any;
};

const OptionSet = ({ name, defaultValue, fieldItem }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const componentOptionValues = fieldItem.ui_component_options?.values || [];
  const options = componentOptionValues.map(value => ({
    label: value,
    value: value,
  }));

  const readOnly = fieldItem.ui_component_options?.readOnly || false;
  const placeholder = fieldItem?.placeholder;

  const transformedDefaultValue = useMemo(
    () =>
      defaultValue
        ? {
            value: defaultValue,
            label: defaultValue,
          }
        : undefined,
    [defaultValue],
  );

  return (
    <FieldLabel fieldItem={fieldItem}>
      <Controller
        control={control}
        defaultValue={transformedDefaultValue}
        name={name}
        render={({ field }) => {
          return (
            <ReactSelect
              {...field}
              styles={{
                control: provided => ({
                  ...provided,
                  borderColor: errors[name] ? 'red' : '#ced4da',
                  minHeight: '3rem',
                }),
                menuPortal: base => ({ ...base, zIndex: 9999 }),
              }}
              className={cx('single-dropdown', { 'is-invalid': errors[name] })}
              isDisabled={readOnly}
              isClearable
              menuPortalTarget={document.body}
              placeholder={placeholder}
              options={options}
              id={field.name}
              closeMenuOnSelect={true}
            />
          );
        }}
      />

      {fieldItem.description && (
        <small className="form-text text-muted">{fieldItem.description}</small>
      )}
    </FieldLabel>
  );
};

OptionSet.defaultProps = {
  defaultValue: '',
};

export default OptionSet;
