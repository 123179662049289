import isValidCoordinates from 'is-valid-coordinates';
import { isArray } from 'lodash';

export interface Coordinates {
  x: number;
  y: number;
}

export interface MSSQLCoordinates {
  points: Array<Coordinates>;
}

export interface NormalisedCoordinates {
  lat: number;
  lng: number;
}

export const parseLocation = (
  coordinates: MSSQLCoordinates | Coordinates,
  asString?: boolean,
): string | undefined | NormalisedCoordinates => {
  if (!coordinates) return undefined;

  const msCoords = coordinates as MSSQLCoordinates;
  const pgCoords = coordinates as Coordinates;

  const isMSSQL = isArray(msCoords.points) && msCoords.points.length;

  const location: NormalisedCoordinates = isMSSQL
    ? { lat: msCoords.points[0].x, lng: msCoords.points[0].y }
    : { lat: pgCoords.y, lng: pgCoords.x };

  if (!isValidCoordinates(location.lng, location.lat)) {
    return undefined;
  }

  if (asString && location) {
    return isMSSQL
      ? `${location.lat},${location.lng}`
      : `${location.lng},${location.lat}`;
  }

  return location;
};
